<div class="client-dashboard">
    <div :class="wrapper">
        <div v-if="!hide_sidebar">
            <Sidebar :role="'admin'" />
        </div>

        <div class="main-content">
            <NavBar @toggle-sidebar="toggle_sidebar" :role="'admin'"/>      
                <router-view></router-view>
        </div>
    </div>
</div>
