<div class="container-fluid">
    <div class="card rounded-3 mt-4 p-0">
    <div class="card-header bg-white border-0">
      Users
      <button type="button" class="btn  btn-dark btn-sm text-xs fw-bold rounded-small shadow float-end" data-bs-toggle="modal" data-bs-target="#addUserModal">
        <i class="fas fa-plus"></i> Add Users
      </button>
    </div>
    <div class="container mt-3">
      <ul class="nav nav-tabs mb-4 bg-white d-flex justify-content-between" id="myTab" role="tablist">
        <li class="nav-item flex-fill me-2" role="presentation">
          <button class="nav-link w-100 fw-bold text-dark text-xs border-0" :class="{ active: currentTab === 'ClientAdmins' }"
                  @click="switchTab('ClientAdmins')">
            Client Admins
          </button>
        </li>
        <li class="nav-item flex-fill me-2" role="presentation">
          <button class="nav-link w-100 fw-bold text-dark text-xs border-0" :class="{ active: currentTab === 'Clients' }"
                  @click="switchTab('Clients')">
            Clients
          </button>
        </li>
        <li class="nav-item flex-fill me-2" role="presentation">
          <button class="nav-link w-100 fw-bold text-dark text-xs border-0" :class="{ active: currentTab === 'Customers' }"
                  @click="switchTab('Customers')">
            Customers
          </button>
        </li>
      </ul>
      <div class="tab-content">
        <div v-if="currentTab === 'ClientAdmins'" class="tab-pane fade show active">
          <table id="users_table" class="table table-clear">
            <thead class="bg-dark text-white">
              <tr>
                <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
                <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Client Admin</th>
                <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Phone Number</th>
                <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Date Joined</th>
                <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(client_admin, index) in client_admins" :key="client_admin.id" class="text-xs">
                <td class="text-xs fw-bold text-start">{{ index + 1 }}</td>
                <td>
                  {{ client_admin.user.first_name }} {{ client_admin.user.last_name }}
                  <p class="text-xxs fw-bold">{{ client_admin.user.email }}</p>
                </td>
                <td class="text-xs text-start">0{{ client_admin.user.phone_number }}</td>
                <td class="text-xs text-start">{{  client_admin.user.date_joined.split('T')[0]  }}</td>
                <td>
                  <button class="btn bg-green btn-sm text-xxs fw-bold rounded-small " >View</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    
        <div v-if="currentTab === 'Clients'" class="tab-pane fade show active">
          <table id="users_table" class="table table-clear">
            <thead class="bg-dark text-white">
              <tr>
                <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
                <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Client</th>
                <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Phone Number</th>
                <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Date Joined</th>
                <th class="text-xxs">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(client, index) in clients" :key="client.id" class="text-xs">
                <td class="text-xs fw-bold text-start">{{ index + 1 }}</td>
                <td>
                  {{ client.user.first_name }} {{ client.user.last_name }}
                  <p class="text-xxs fw-bold">{{ client.user.email }}</p>
                </td>
                <td class="text-xs text-start">0{{ client.user.phone_number }}</td>
                <td class="text-xs text-start">{{ client.user.date_joined.split('T')[0] }}</td>
                <td>
                  <button class="btn bg-green btn-sm text-xxs fw-bold rounded-small" >View</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="currentTab === 'Customers'" class="tab-pane fade show active">
          <table id="users_table" class="table table-clear">
            <thead class="bg-dark text-white mb-1">
              <tr>
                <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
                <th class="small text-xxs">{{ currentTab.slice(0, -1) }}</th>
                <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Phone Number</th>
                <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Date Joined</th>
                <th class="text-xxs">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(customer, index) in customers" :key="customers.id" class="text-xs">
                <td class="text-xs text-start">{{ index + 1 }}</td>
                <td>
                  {{ customer.customer.first_name }} {{ customer.customer.last_name }}
                  <p class="text-xxs fw-bold">{{ customer.customer.email }}</p>
                </td>
                <td class="text-xs text-start">0{{ customer.customer.phone_number }}</td>
                <td class="text-xs text-start">{{ customer.customer.date_joined.split('T')[0] }}</td>
                <td>
                  <button class="btn bg-green btn-sm text-xxs fw-bold rounded-small" @click="view_cutsomer_data(customer.customer.id)" >View</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
    <!-- Add User Modal -->
    <div class="modal fade" id="addUserModal" tabindex="-1" aria-labelledby="addUserModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addUserModalLabel"><strong>Add User</strong></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="add_user">
              <div class="row mb-3">
                <div class="col">
                  <label class="form-label fw-bold"><small>First name</small></label>
                  <input v-model="first_name" type="text" class="form-control text-xs" placeholder="First name" name="first_name"
                    required>
                  <div class="first-name-message d-none">First name is required</div>
                </div>
                <div class="col">
                  <label class="form-label fw-bold"><small>Last name</small></label>
                  <input v-model="last_name" type="text" class="form-control text-xs" placeholder="Last name" name="last_name"
                     required>
                  <div class="last-name-message d-none">Last name is required</div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label fw-bold"><small>Email</small></label>
                <input v-model="email" type="email" class="form-control text-xs" name="email" placeholder="Email" required>
                <div class="email-message d-none">Email is required</div>
              </div>
              <div class="mb-3">
                <label class="form-label fw-bold"><small>Phone number</small></label>
                <input v-model="phone_number" type="tel" class="form-control text-xs" placeholder="Enter phone number" name="phone_number" maxlength="10"
                  @input="validate_phone_number" required>
              </div>
              <div class="mb-3">
                <label class="form-label fw-bold"><small>User Type</small></label>
                <select v-model="role" class="form-select text-xs" required>
                  <option value="" disabled hidden>Select user Type</option>
                  <option value="Client">Client</option>
                  <option value="ClientAdmin">Client Admin</option>
                </select>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn bg-green btn-sm text-xs fw-bold rounded-small">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  