<div class="row">
    <div class="card card-description border-3 col">

        <div class="decorative-card">
            <div class="row">
                <h6 class="mb-3 text-lg fw-bold">Admin Client: {{ first_name }} {{ last_name }}</h6>
                <span class="text-xs fw-bold">Email Address: <span
                    class="ms-sm-2 fw-light text-dark">{{ email }}</span>
                </span>
                <span class="text-xs fw-bold">Date Joined:  <span
                    class="ms-sm-2 fw-light text-dark">{{ date_joined }}</span>
                </span>
            </div>
        </div>
        <hr class="horizontal dark">
        <p class="text-uppercase text-sm text-blue fw-bold">Edit User Information</p>
        <form>
            <div class="row mb-3">
              <div class="col">
                <label class="form-label fw-bold"><small>First name</small></label>
                <input v-model="first_name" type="text" class="form-control text-xs" placeholder="First name">
              </div>
              <div class="col">
                <label class="form-label fw-bold"><small>Last name</small></label>
                <input v-model="last_name" type="text" class="form-control text-xs" placeholder="Last name">
              </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label fw-bold"><small>Email</small></label>
                    <input v-model="email" type="email" class="form-control text-xs" placeholder="Email">
                    </div>
                    <div class="col">
                    <label class="form-label fw-bold"><small>Phone number</small></label>
                    <input v-model="phone_number" type="tel" class="form-control text-xs" placeholder="Enter phone number">
                </div>
            </div>


            <div class="modal-footer">
              <button type="submit" class="btn bg-green btn-sm text-xs fw-bold rounded-pill" data-bs-dismiss="modal" @click.prevent="edit_user(get_user_id)">Submit</button>
            </div>
        </form>
    </div>
</div>