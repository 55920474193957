import { allow_num  } from "@/utils/validations";
export default {
    name: 'IdNumberInput',
    data() {
        return {
          id_number: '',
        };
    },
    methods: {
        allow_num(event) {
            this.id_number = allow_num(event);
        }
    },
    watch: {
        id_number() {
          this.$emit('update-id-number', this.id_number);
        }
    }
}