import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";

export default {
    name: 'CIPCVerification',
    mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
    components:{
        BreadCrumb,
        ClientLogo
    },
    data: () => ({
        company_name: '',
        reg_number: '',
        reg1: '',
        reg2: '',
        reg3: ''
    }),

    computed: {
        workflow_id() {
          return this.$store.getters.get_workflow_id;
        }
      },

    methods: {
        validate_reg_number(event) {
            let input = event.target.value.replace(/\D/g, ''), position = event.target.selectionStart;
            if (event.key === 'Backspace' && position === 0) return;

            this.reg1 = input.slice(0, 4);
            this.reg2 = input.slice(4, 10);
            this.reg3 = input.slice(10);

            this.reg_number = [this.reg1, this.reg2, this.reg3].filter(Boolean).join('/').slice(0, 14);

            this.$nextTick(() => {
                let new_position = position + (this.reg_number.length - input.length);
                event.target.selectionStart = event.target.selectionEnd = new_position;
            });
        },

        save_cipc_info() {
            if (!this.company_name.trim()) {
                $('.company-message').removeClass('d-none');
                setTimeout(() => {
                    $('.company-message').addClass('d-none');
                }, 3000);
                return false;
            }
            if (!this.reg_number.trim()) {
                $('.reg-message').removeClass('d-none');
                setTimeout(() => {
                    $('.reg-message').addClass('d-none');
                }, 3000);
                return false;
            }
            const TOKEN = localStorage.getItem('token');
            const COMPANY_INFO_API = `${process.env.API_BASE_URL}/verify_api/compliance_verification/cipc/`;

            const FORM_DATA = {
                company_name: this.company_name,
                reg1: this.reg1,
                reg2: this.reg2,
                reg3: this.reg3,
                workflow_id:  this.workflow_id
            };

            $.ajax({
                url: COMPANY_INFO_API,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}` 
                },
                data: JSON.stringify(FORM_DATA),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    if (response === "No Record Found") {
                        Swal.fire({
                            icon: 'error',
                            title: response,
                            confirmButtonText: 'OK'
                        });  
                    }else if(response.status === "success") {
                        this.$router.push({ name: 'director_info', params: { workflow_id: this.workflow_id } });

                    }else{
                        Swal.fire({
                            icon: 'error',
                            text: "Our service is currently unavailable. Please contact your service provider and try again later",
                        })
                    }
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Error verifying company'

                    if (error.responseJSON) {
                        if (error.responseJSON.Error) {
                            error_message = error.responseJSON.Error;
                        }if (error.responseJSON.reg3) {
                            error_message = 'Please enter a valid company registration number';
                        }else {
                            error_message = error.responseJSON;
                        }
                        $('.error-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.error-message').text('').addClass('d-none');
                            }, 3000);
                    }else {
                        $('.error-message').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.error-message').text('').addClass('d-none');
                        }, 3000);
                    }
                }
            });
        }
    },

    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
        this.get_customer_data();
      }
};