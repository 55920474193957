import NavBar from '@/components/NavBar/NavBar.vue';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import { validateToken } from '@/utils/auth';

export default {
    name: 'FormManagement',
    pageTitle: 'Form Management', 
    components: {
        NavBar
    },
    data: () => ({
        services: [],
        service: "",
        service_name: '',
        service_types:[],
        service_type : '',
        loading: false
    }),
    methods: {
      fetch_service_types() {
        const TOKEN = localStorage.getItem('token');
        const GET_SERVICE_TYPES_API = `${process.env.API_BASE_URL}/verify_api/form_management_api/get_service_types/`;
  
        $.ajax({
          url: GET_SERVICE_TYPES_API,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            this.loading = true;
          },
          success: (response) => {
            Swal.close();
            this.service_types = response.service_types || [];
            this.loading = false;
          },
          error: (error) => {
            this.loading = false;
            Swal.close();
            console.error('Failed to fetch service types:', error);
          }
        });
      },
        get_custom_service() {
          const TOKEN = localStorage.getItem('token');
          const GET_CUSTOM_SERVICE_API = `${process.env.API_BASE_URL}/verify_api/form_management_api/get_custom_service_api/`;
          $.ajax({
            url: GET_CUSTOM_SERVICE_API,
            method: 'GET',
            contentType: 'application/json',
            headers: {
              Authorization: `Token ${TOKEN}`
            },
            beforeSend: () => {
              this.loading = true;
            },
            success: (response) => {
            Swal.close();
            this.services = response.data || [];
            this.loading = false;
            this.$nextTick(() => {
              this.initDataTable(); // Initialize DataTable after data is loaded
            });
            },
            error: () => {
              this.loading = false;
              Swal.close();
            }
          });
        },

        create_custom_service() {
          const TOKEN = localStorage.getItem('token');

          const FORM_DATA = {
              service_name: this.service_name,
              service_type: this.service_type
          };
          const CREATE_CUSTOM_SERVICE_URL = `${process.env.API_BASE_URL}/verify_api/form_management_api/create_custom_service_api/`;
    
          $.ajax({
            url: CREATE_CUSTOM_SERVICE_URL,
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(FORM_DATA),
            headers: {
              Authorization: `Token ${TOKEN}`
            },
            beforeSend: () => {
              Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                }
              });
            },
            success: (response) => {
              Swal.close();
              const service_id = response.data.service_id;
              this.$store.commit('set_custom_service_id', service_id);
              Swal.fire({
                  icon: 'success',
                  text: response.message,
                  confirmButtonColor: '#59FF74',
                  showConfirmButton: true
                  })
                  .then(() => {
                      window.location.reload();
                      $('#add_service').modal('hide');
                    });
              },
              error: (error) => {
                  Swal.close();
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.responseJSON.message,
                    confirmButtonColor: '#59FF74',
                    showConfirmButton: true
                  });
              },
          });
        },

        manage_service(service_id){
          this.$store.dispatch('update_service_id', service_id);
          this.$router.push({ name: 'manage_form'});
        },
        initDataTable() {
          this.$nextTick(() => {
            if ($.fn.dataTable.isDataTable('#form_table')) {
              $('#form_table').DataTable().destroy();
            }
            $('#form_table').DataTable({
              responsive: true
            });
          });
        },
  
      },
      mounted() {
        if (!validateToken(this.$router)) {
          return;
      }
        this.get_custom_service();
        this.fetch_service_types();
      }
  }
