import GetQuestions from '@/components/GetQuestions/GetQuestions.vue';

export default {
  name: 'UserLogin',
  components: {
    GetQuestions,
  },
  data: () => ({
    username: '',
    password: '',
    responses: [],
    client_admin_id: sessionStorage.getItem('client_admin_id'),
    reset_url: `${process.env.API_BASE_URL}/verify_api/password_reset/`
  }),
  mounted(){
    const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
      if (GET_CLIENT_ID) {
        this.store_responses();
      }
  },
  methods: {
    store_responses(dynamic_fields = []) {
      this.responses = dynamic_fields;
    },
    login() {

      if (!this.username.trim()) {
        $('.username-message').removeClass('d-none');
        setTimeout(() => {
          $('.username-message').addClass('d-none');
        }, 3000);
        return false;
      }
      if (!this.password.trim()) {
        $('.password-message').removeClass('d-none');
        setTimeout(() => {
          $('.password-message').addClass('d-none');
        }, 3000);
        return false;
      }

      const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
      const LOGIN_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/login_api/`;
      // Create the basic form data as an object
      const FORM_DATA = {
        client_id: GET_CLIENT_ID,
        username: this.username,
        password: this.password,
        responses: this.responses.map(field => ({
          service_id: field.service_id,
          question_id: field.question_id,
          value: field.value
        }))
      };

      // Check if any response includes a file
      const has_file = this.responses.some(field => field.has_file);

      let data_to_send;
      if (has_file) {
          // If there's a file, create a FormData instance
          const form_data = new FormData();
          form_data.append('client_admin_id', GET_CLIENT_ID);
          form_data.append('username', this.username);
          form_data.append('password', this.password);

          // Append responses, handling files if present
          this.responses.forEach((field, index) => {
              form_data.append(`responses[${index}][service_id]`, field.service_id);
              form_data.append(`responses[${index}][question_id]`, field.question_id);
              if (field.has_file) {
                  form_data.append(`responses[${index}][uploaded_file]`, field.value);
              } else {
                  form_data.append(`responses[${index}][value]`, field.value);
              }
          });

          data_to_send = form_data;
      } else {
          data_to_send = JSON.stringify(FORM_DATA);
      }

      // Determine content type based on the presence of files
      const content_type = has_file ? false : 'application/json';

      $.ajax({
        url: LOGIN_URL,
        method: 'POST',
        data: data_to_send,
        processData: !has_file,  // Do not process data if using FormData
        contentType: content_type,
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          localStorage.setItem('user_id', response.user_id);
          localStorage.setItem('token', response.token);
          localStorage.setItem('user_state', 'logged_in');
          sessionStorage.setItem('client_admin_id', response.client_admin_id);
          this.handleLogin();
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Error Logging in'
          if (error.responseText) {
            const ERROR_RESPONSE = JSON.parse(error.responseText);
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
              $('.login-message').text(error_message).removeClass('d-none');
              setTimeout(() => {
                $('.login-message').text('').addClass('d-none');
              }, 3000);
            }
          }
        }
      });
    },
    handleLogin() {
      this.$router.push({ name: 'user_otp' });
    }
  }
};