import { allow_num,validate_id  } from "@/utils/validations";
import UserInfoFields from "@/components/UserInfoFields/UserInfoFields.vue";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";

export default {
    components:{
        UserInfoFields, 
        BreadCrumb,
        ClientLogo
    },
    name: 'ProofOfIncomeOnline',
    mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
    data: () => ({ 
        id_number: '',
        account_number: '',
        bank_name: '',
        error_messages: {
            id_number: '',
            account_number: '',
            bank_name: '',
            bank_statement: ''
        }

    }),
    computed: {
        workflow_id() {
          return this.$store.getters.get_workflow_id;
        }
      },
    methods: {
        validate_fields() {
            this.error_messages = {};
    

            if (!this.id_number) {
              this.error_messages.id_number = "ID Number is required.";
          } else if (!validate_id(this.id_number)) {
              this.error_messages.id_number = "ID Number is invalid. Please provide a valid ID number.";
          }
    
            if (!this.account_number) {
                this.error_messages.account_number = "Bank Account Number is required.";
            }
    
            if (!this.bank_name) {
                this.error_messages.bank_name = "Please select a bank.";
            }
            return Object.keys(this.error_messages).length === 0;
    },
        handle_input(event) {
            this.id_number = allow_num(event);
        },
        online_proof_of_income() {
            if (!this.validate_fields()) {
                return;
            }

            const TOKEN = localStorage.getItem('token');
            const ONLINE_PROOF_OF_INCOME_URL = `${process.env.API_BASE_URL}/verify_api/personnel_verification/online_proof_of_income_api/`;
        
            this.loading = true;
            this.error = '';
            const DATA = {
                id_number: this.id_number,
                bank_name: this.bank_name,
                account_number: this.account_number,
                workflow_id: this.workflow_id
            };
            
 
            $.ajax({
            url: ONLINE_PROOF_OF_INCOME_URL,
            method: 'POST',
            contentType: 'application/json',
            headers: {
                Authorization: `Token ${TOKEN}`
            },
            data: JSON.stringify(DATA),
            beforeSend: () => {
                Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => Swal.showLoading(),
                });
            },
            success: (response) => {
                Swal.close();
                this.fetch_current_service();
                var status_message = response.status;

                if (status_message == "success") {
                this.fetch_current_service();
                } else if (status_message == "Results not found please contact your service provider") {
                Swal.fire({
                    icon: 'error',
                    text: "Our service is currently unavailable. Please contact your service provider and try again later",
                })
                }
            },
            error: () => {
              Swal.close();
            }
            });
        }
    },

    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
        this.get_customer_data();
      }
}