<div v-if="customers.length === 0" class="card shadow border-0 mt-4">
    <div class="card-body">
      <div class="text-center">
        <img src="@/assets/images/no-project.png" alt="Logo" class="no-project-img"/>
        <h5>There are no Customers Created yet!</h5>
      </div>
    </div>
</div>

  <div v-else class="card shadow border-0 mt-4">
    <div class="card-body">
      <p class="text-s fw-bold">Customers</p>

      <button type="button" class="btn btn-success text-xxs mb-4">
        <i class="fas fa-plus"></i> New Customer
      </button>

      <div class="table-responsive">
        <table id="table" class="display nowrap text-xs w-100">
          <thead>
            <tr>
              <th>ID</th>
              <th>Customer Name</th>
              <th>Date Joined</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody class="text-secondary">
            <tr v-for="customer in customers" :key="customer.id">
              <td>{{ customer.id }}</td>
              <td>{{ customer.name }}</td>
              <td>{{ customer.date_joined }}</td>
              <td>
                <button class="btn btn-success text-xxxs w-40 fw-bold">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  