export default {
    name: 'CompanyVerification',

    data: () => ({
        company_name: '',
        reg_number: '',
    }),

    methods: {
        validate_reg_number(event) {
            let input_value = event.target.value;
            let cursor_position = event.target.selectionStart;

            if (event.key === 'Backspace' && cursor_position === 0) {
                return;
            }

            let clean_input = input_value.replace(/\D/g, '');
            let unformatted_length = clean_input.length;

            let formatted_input;
            if (clean_input.length > 4 && clean_input.length <= 8) {
                formatted_input = clean_input.replace(/(\d{4})(\d{0,6})/, '$1/$2');
            } else if (clean_input.length > 8) {
                formatted_input = clean_input.replace(/(\d{4})(\d{0,6})(\d{0,2})/, '$1/$2/$3');
            } else {
                formatted_input = clean_input;
            }

            formatted_input = formatted_input.slice(0, 14);
            this.reg_number = formatted_input;

            this.$nextTick(() => {
                let new_cursor_position = cursor_position + (formatted_input.length - unformatted_length);
                event.target.selectionStart = new_cursor_position;
                event.target.selectionEnd = new_cursor_position;
            });
        },

        save_company_info() {
            if (!this.company_name.trim()) {
                $('.company-message').removeClass('d-none');
                setTimeout(() => {
                    $('.company-message').addClass('d-none');
                }, 3000);
                return false;
            }
            if (!this.reg_number.trim()) {
                $('.reg-message').removeClass('d-none');
                setTimeout(() => {
                    $('.reg-message').addClass('d-none');
                }, 3000);
                return false;
            }

            const REGISTRATION_NUMBER = this.reg_number.replace(/\//g, '');
            const TOKEN = localStorage.getItem('token');
            const COMPANY_INFO_API = `${process.env.API_BASE_URL}/verify_api/verify_company_api/company_info/`;

            const FORM_DATA = {
                company_name: this.company_name,
                company_reg_no: REGISTRATION_NUMBER
            };

            $.ajax({
                url: COMPANY_INFO_API,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}` 
                },
                data: JSON.stringify(FORM_DATA),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: () => {
                    Swal.close();
                    this.$router.push('/company_pending');
                    
                },
                error: (error) => {
                    Swal.close();
                
                    let error_message = 'Error verifying company'

                    if (error.responseText) {
                        const ERROR_RESPONSE = JSON.parse(error.responseText);
                    
                        if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
                            error_message = ERROR_RESPONSE.error;

                            if(ERROR_RESPONSE.error === "You have no more attempts left") {
                                $('.error-messages').text(error_message).removeClass('d-none');
                        
                                setTimeout(() => {
                                    this.$router.push('/company_pending');
                                }, 3000);

                            } else {
                                $('.error-messages').text(error_message).removeClass('d-none');
                                setTimeout(() => {
                                    $('.error-messages').text('').addClass('d-none');
                                }, 3000);
                            }
                        }
                    }
                }
            });
        }
    }
};