import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { validateToken } from '@/utils/auth';

export default {
  name: 'CustomerConsent',
  mixins: [MAIN_CONTROLLER],
  data: () => ({
    terms_and_conditions_url: null,
    status: null,
    get_consent: true,
    workflow_id:null,
 
  }),
  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },
  methods: {
    get_terms_and_conditions() {
      if (!this.workflow_id) {
        this.status = 'no_terms_and_conditions';
        this.terms_and_conditions_url = null;
        this.get_consent = true;
        return;
      }
      const TOKEN = localStorage.getItem('token');
      const GET_CUSTOMER_TERMS_AND_CONDITIONS_API = `${process.env.API_BASE_URL}/verify_api/customer_management_api/get_customer_terms_and_conditions/?workflow_id=${this.workflow_id}`;

      $.ajax({
        url: GET_CUSTOMER_TERMS_AND_CONDITIONS_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          this.status = response.status;
          
          if (response.status === 'terms_and_conditions_available') {
            this.terms_and_conditions_url = response.terms_and_conditions_url;
            this.get_consent = true;
          } else if (response.status === 'no_terms_and_conditions') {
            this.terms_and_conditions_url = null;
            this.get_consent = true;
          }
        },
        error: (error) => {
          Swal.close();
          Swal.fire({
            title: 'Error',
            text: error.responseJSON?.error || 'An error occurred while fetching terms and conditions',
            icon: 'error'
          });
        }
      });
    },
    
    terms_and_conditions_value(is_confirmed) {
      if (is_confirmed === true)
      {

        this.save_terms_and_conditions(is_confirmed)
      }else{
        Swal.fire({
          title: 'Are you sure you want to decline?',
          text: "You cannot proceed with the application if consent is not provided.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes'
        }).then(() => {
          this.save_terms_and_conditions(is_confirmed)

        });
      }
    },

    save_terms_and_conditions(is_confirmed) {
      const TOKEN = localStorage.getItem('token');
      const CONSENT_URL = `${process.env.API_BASE_URL}/verify_api/customer_management_api/save_customer_terms_and_conditions/`;
      const FORM_DATA = {
        is_confirmed: is_confirmed,
        workflow_id: this.workflow_id
      };
      
      $.ajax({
        url: CONSENT_URL,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify(FORM_DATA),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          if(response.status)
            {
              let status = response.status.toLowerCase()
              if (status === 'success' && is_confirmed === true) {
                this.$store.dispatch('update_workflow_id', this.workflow_id);
                this.fetch_current_service();
              } else if (status === 'success' && is_confirmed === false) {
  
                this.$router.push('/thank_you_page');
    
              }
            }
        },
        error: () => {
          let error_message = 'Error while providing consent';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    }
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.workflow_id = this.$route.query.workflow_id || localStorage.getItem('workflow_id');
    this.get_terms_and_conditions();
  }
};