<div class="container-fluid">
    <div class="card rounded-3 p-2 container mt-4 border-0 shadow" ref="services_container">
      <div class="row">
        <div class="col-md-3">
          <div class="tabs-container mt-4 mb-3">
            <div class="d-flex flex-column justify-content-center">
              <button 
                class="tab-button rounded-3 btn btn-lg p-3 shadow mb-5 mt-5" 
                :class="{ 'bg-green text-dark fw-bold text-xs': activeTab === 'personnel', 'bg-dark text-white text-xs': activeTab !== 'personnel' }" 
                @click="showTab('personnel')"
              >
                Personnel Verification
              </button>
              <button 
                class="tab-button rounded-3 btn btn-lg p-3 shadow mb-5" 
                :class="{ 'bg-green text-dark text-xs fw-bold': activeTab === 'compliance', 'bg-dark text-white text-xs': activeTab !== 'compliance' }" 
                @click="showTab('compliance')"
              >
                Compliance Verification
              </button>
              <button 
                class="tab-button rounded-3 btn btn-lg p-3 shadow mb-5" 
                :class="{ 'bg-green text-dark text-xs fw-bold': activeTab === 'credit', 'bg-dark text-white text-xs': activeTab !== 'credit' }" 
                @click="showTab('credit')"
              >
                Credit Verification
              </button>
            </div>
          </div>
        </div>

  <div class="col-md-9">
    <h6 class="border-bottom pb-2 mb-0 ps-2 service-section-custom-width">
      Select your services
    </h6>
     <!-- Personnel Verification Content -->
     <div id="personnel" class="tab-content" v-show="activeTab === 'personnel'">
      <div class="card-row scrollable-container">
        <div class="d-flex overflow-auto">
          <div class="col-md-4 mb-3 mt-2 mx-1" v-for="service in personnel_services" :key="service.id">
            <div class="card rounded-3 card-bg shadow border-1 h-80 border-1">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <label class="switch">
                    <input type="checkbox" v-model="service.is_selected" class="text-xs" @change="select_services(service)">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div class="text-center">
                  <p class="fw-bold text-xs service-heading">{{ service.service_name }}</p>
                </div>
                <button href="#" class="bg-green rounded-small text-xxs p-1 fw-bold shadow border-0 secondary-text-dark" @click="open_modal(service)">
                  Read more <i class="fa fa-arrows" aria-hidden="true"></i>
                </button>
              </div>
      
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Compliance Verification Content -->
    <div id="compliance" class="tab-content" v-show="activeTab === 'compliance'">
      <div class="card-row p-2 scrollable-container">
        <div class="d-flex overflow-auto">
          <div class="col-md-3 mb-3 mx-2" v-for="service in compliance_services" :key="service.id">
            <div class="card rounded-3 card-bg shadow border-1 h-100 service-custom-width" >
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <label class="switch">
                    <input type="checkbox" v-model="service.is_selected" @change="select_services(service)">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div class="text-center">
                  <p class="fw-bold text-xs service-heading mt-1">{{ service.service_name }}</p>
                </div>
                <a href="#" class="bg-green rounded-3 text-xxs p-1 fw-bold shadow secondary-text-dark" @click="open_modal(service)">
                  Read more <i class="fa fa-arrows" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Credit Verification Content -->
    <div id="credit" class="tab-content" v-show="activeTab === 'credit'">
      <div class="card-row p-2 scrollable-container">
          <div class="col-md-3 mb-3 mx-2" v-for="service in credit_services" :key="service.id">
            <div class="card rounded-3 card-bg shadow border m-2 h-80 service-custom-width">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <label class="switch">
                    <input type="checkbox" 
                    v-model="service.is_selected"
                    
                    @change="select_services(service)">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div class="text-center">
                  <p class="fw-bold text-xs service-heading">{{ service.service_name }}</p>
                </div>
                <a href="#" class="bg-green rounded-3 text-xxs p-1 fw-bold shadow secondary-text-dark" @click="open_modal(service)">
                  Read more <i class="fa fa-arrows" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
      </div>
    </div>

    <!-- Your Services Section -->
    <div class="your-services-section mt-2">
      <h6 class="border-bottom pb-2 mb-0 ps-2 service-section-custom-width">
        Your Selected Services
      </h6>
      <div class="row card-row p-2 scrollable-container mb-0">
        <div class="col-md-3 mb-3" v-for="service in selected_services" :key="service.id">
          <div class="card rounded-3 border-0 bg-green h-100">
            <div class="card-body">
                <div class="text-center"> 
                  <p class="fw-bold text-xs service-heading secondary-text-dark">{{ service.service_name }}</p>
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
     
 
      <div class="d-flex justify-content-end mt-3 mb-3 me-3">
        <router-link :to="{ name: 'services' }" class="text-white">
          <button class="btn btn-sm shadow  bg-green rounded-small fw-bold text-xs">Continue</button>
        </router-link>
      </div>
    </div>
</div>

  <!-- Modal -->
  <div class="modal fade" id="service_description_modal" tabindex="-1" aria-labelledby="service_description_label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-s" id="service_description_label"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-xs">
          <p><span id="service_description"></span></p>
          <p><strong>This service returns information such as:</strong></p>
          <ul id="service_output"></ul>
          <p><strong>Required Inputs:</strong></p>
          <ul id="service_input"></ul>
          <p><strong>Cost Per Request:</strong></p>
          <ul>
            <li>R <span id="service_cost"></span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="d-none">
    <li id="input_items"></li>
  </div>

  <div class="d-none">
    <li id="output_items"></li>
  </div>