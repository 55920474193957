<div class="container-fluid mt-4">
  <div ref="services_container">
    <div class="row mt-0">
      <div class="col-md-12">
        <div class="card rounded-3 p-0 mt-4">
          <div class="card-header border-0 bg-white">
            Selected Services in Your Workflow
          </div>

          <div class="card-body">
            <table id="order-service-table" class="table table-responsive w-100">
              <thead class="bg-dark text-white">
                <tr>                  
                  <th class="text-uppercase text-xxs font-weight-bolder opacity-7">Service Name</th>
                  <th class="text-uppercase text-xxs font-weight-bolder opacity-7">Order Service</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(service, index) in sorted_services" :key="service.service_id">
                  <td v-if="service.type ==='form'" class="text-xxs ">{{ service.category.category_name }}</td>
                  <td v-else class="text-xxs">{{ service.service_name }}</td>
                  <td>
                    <!-- Button to Open Modal -->
                    <button class="btn btn-sm bg-green rounded-small secondary-text-dark text-xxs" type="button" @click="open_modal(service)">
                      <i class="bi bi-list"></i> {{ service.selected_order ? service.selected_order : 'Select Order' }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-12">
              <div align="right" class="p-2">
                <button type="button" class="btn bg-green rounded-small text-xxs shadow secondary-text-dark" @click="route_to">Confirm Order</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Structure -->
  <div v-if="selectedService" id="yourModal" class="modal fade show order-service-modal" tabindex="-1" aria-labelledby="modalTitle" aria-modal="true" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content rounded-3">
        <div class="modal-header">
          <h6 class="modal-title" id="modalTitle">Order Numbering</h6>
          <button type="button" class="btn-close" aria-label="Close" @click="close_modal"></button>
        </div>
        <div class="modal-body">
          <!-- Dropdown List for Numbers -->
          <div class="form-group">
            <label for="orderSelect" class="text-xs">Select Order</label>
            <div class="input-group">
              <select v-model="selectedOrder" id="orderSelect" class="form-select text-xs rounded-small shadow-sm">
                <option v-for="n in maxOrder" :key="n" :value="n">{{ n }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn bg-green rounded-small shadow text-xs" @click="save_order">Update</button>
        </div>
      </div>
    </div>
  </div>
</div>