import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import Swal from 'sweetalert2';
import NavBar from '@/components/NavBar/NavBar.vue';
import { validateToken } from '@/utils/auth';

export default {
  name: 'project_table',
  components: {
    NavBar
  },
  data: () => ({projects: []
    ,onboarding_link: '',
    project_name: '',
    project_description: '',
    active_workflows: 0,
    date_created: '',
    loading: false,
    selected_project_id: null,
    success_message: '',  // Add success message state
    error_messages: ''}),

  computed: {
    project_id() {
      return this.$store.getters.get_project_id;
    }
  },
  methods: {
    get_projects() {
      const TOKEN = localStorage.getItem('token');
      const GET_PROJECTS_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_projects_api/`;
      $.ajax({
        url: GET_PROJECTS_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          this.projects = response.projects;
          this.onboarding_link = response.onboarding_link;
  
          this.loading = false;
  
          this.$nextTick(() => {
            if ($.fn.dataTable.isDataTable('#project-table')) {
              $('#project-table').DataTable().destroy();
            }
            $('#project-table').DataTable({
              responsive: true,
              paging: true,
              searching: true,
              ordering: true,
            });
          });
        },
        error: () => {
          this.loading = false;
          Swal.close();
        }
      });
    },
    formatDate(date) {
      const parsedDate = new Date(date);
      if (!isNaN(parsedDate.getTime())) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return parsedDate.toLocaleDateString(undefined, options);
      }
      return 'Invalid Date';
    },
    validate_inputs() {
      if (!this.project_name.trim()) {
        $('.project-name-messages').removeClass('d-none');
        setTimeout(() => {
          $('.project-name-messages').addClass('d-none');
        }, 3000);
        return false;
      }

      if (!this.project_description.trim()) {
        $('.project-description-messages').removeClass('d-none');
        setTimeout(() => {
          $('.project-description-messages').addClass('d-none');
        }, 3000);
        return false;
      }

      return true;
    },
    create_project() {
      if (!this.validate_inputs()) {
        return;
      }
      const TOKEN = localStorage.getItem('token');
      const COMPANY_PROFILE_ID = localStorage.getItem('company_profile_id');

      const FORM_DATA = {
        project_name: this.project_name,
        project_description: this.project_description,
        company_profile_id: COMPANY_PROFILE_ID,
      };

      const PROJECT_API_URL = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/create_project_api/`;

      $.ajax({
        url: PROJECT_API_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          localStorage.setItem('user_state', 'created_project');
          let status = response.status;

          if (status === 'success'){
            const project_id = response.project_id;
            this.$store.commit('set_project_id', project_id);
            this.$router.push({ name: 'project_dashboard'});
            $('#create_project').modal('hide');
          }
          else{
            let error_message = response.message;
            // Display the error message using SweetAlert2
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonColor: '#59FF74',
              showConfirmButton: true
            });
          }
          
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Error Creating Project';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;

              $('.error-messages').text(error_message).removeClass('d-none');

              setTimeout(() => {
                $('.error-messages').text('').addClass('d-none');
              }, 3000);
            }
          }
        }
      });
    },
    viewProject(project_id) {
      this.$store.dispatch('update_project_id', project_id)
      this.$router.push({ path: `project_dashboard/` });
    },
    edit_project(project_id) {
      const project = this.projects.find(proj => proj.id === project_id);
      if (project) {
        this.selected_project_id = project_id; // Set the selected project ID here
        this.project_name = project.project_name;
        this.project_description = project.project_description;
        this.$nextTick(() => {
          $('#edit_project_modal').modal('show');
        });
      }
    },
    update_project() {
      if (!this.validate_inputs()) {
        return;
      }

      const TOKEN = localStorage.getItem('token');
      const FORM_DATA = {
        project_name: this.project_name,
        project_description: this.project_description
      };

      const EDIT_PROJECT_API_URL = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/edit_project_api/${this.selected_project_id}`;

      $.ajax({
        url: EDIT_PROJECT_API_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Updating project...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: () => {
          Swal.close();
          $('#edit_project_modal').modal('hide');
          this.get_projects(); // Refresh the projects list
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Error Updating Project';

          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;

            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;

              $('.error-messages').text(error_message).removeClass('d-none');

              setTimeout(() => {
                $('.error-messages').text('').addClass('d-none');
              }, 3000);
            }
          }
        }
      });
    },
    copy_onboarding_link() {
      navigator.clipboard.writeText(this.onboarding_link)
        .then(() => {
          this.success_message = 'Link copied to clipboard!';
          this.error_messages = '';
        })
        .catch(() => {
          this.error_messages = 'Failed to copy the link.';
          this.success_message = '';
        });
    }
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
  }
    this.get_projects();
  }
};
