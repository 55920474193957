import { validate_id  } from "@/utils/validations";
import IdNumberInput from '@/components/IdNumberInput/IdNumberInput.vue'
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";

export default {
  name: 'EligibilityService',
  mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],

  components: {
    IdNumberInput,
    BreadCrumb,
    ClientLogo
  },

  data:() =>({ 
      id_number: '',
      loading: false,
      error: ''
    }),
  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },
  methods: {
    handle_id_input(id_number) {
      this.id_number = id_number; // Update the id_number data property with the new value
     
    },
    submit_id() {
      if (!this.id_number.trim()) {
      $('.id-message').removeClass('d-none');
      setTimeout(() => {
      $('.id-message').addClass('d-none');
      }, 3000);
      return false;
      }
      
      if (!validate_id(this.id_number)) {
      $('.invalid-message').removeClass('d-none');
      setTimeout(() => {
      $('.invalid-message').addClass('d-none');
      }, 3000);
      return false;
    }
    this.eligibility_solution()
    },
    eligibility_solution() {
      const TOKEN = localStorage.getItem('token');
      const ELIGIBILITY_SOLUTION_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/eligibility_solution/`;
    
      this.loading = true;
      this.error = '';
      const DATA = {
        id_number: this.id_number,
        workflow_id: this.workflow_id,
      };

      $.ajax({
        url: ELIGIBILITY_SOLUTION_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify(DATA),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
          });
        },
        success: (response) => {
          Swal.close();
          this.fetch_current_service();
          var status_message = response.status;

          if (status_message == "success") {
            this.fetch_current_service();
          } else if (status_message == "error") {
            Swal.fire({
              icon: 'error',
              text: "Our service is currently unavailable. Please contact your service provider and try again later",
            })
          }
        },
        error: (error) => {
          this.loading = false;
          Swal.close();
          let error_message = 'Something went wrong!';

          if (error.responseJSON && error.responseJSON.message) {
            error_message = error.responseJSON.message;
            $('.error-message').text(error_message).removeClass('d-none');
                setTimeout(() => {
                    $('.error-message').text('').addClass('d-none');
                }, 3000);
          }else {
              $('.error-message').text(error_message).removeClass('d-none');
                  setTimeout(() => {
                      $('.error-message').text('').addClass('d-none');
                  }, 3000);
            }
        }
      });
    }
  },

  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_customer_data();
  }
};