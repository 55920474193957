export default {
    name: 'DirectorConsent',

    data: () => ({
        consent_provided: true,
    }),

    methods: {
        accept() {
            const TOKEN = localStorage.getItem('token');
            const CONSENT_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/terms_and_conditions_api/`;

            const FORM_DATA = {
                consent_provided: this.consent_provided
            };

            $.ajax({
                url: CONSENT_URL,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}` 
                },
                data: JSON.stringify(FORM_DATA),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: () => {
                    Swal.close();

                    this.$router.push('/facial_biometrics_verification');
                },
                error: () => {
                    Swal.close();
                }
            });
        },
        decline() {
            Swal.fire({
                title: 'Are you sure you want to decline?',
                text: "You cannot proceed with the application if consent is not provided.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$router.push('/');
                }
            });
        },
    },
};