import { validateToken } from '@/utils/auth';

export default {
    name: 'AFISCriminalCheck',
    data: () => ({
        first_names: '',
        surname: '',
        id_number: '',
        phone_number: '',
        email_address: '',
        json_data: {},
    }),
    methods: {
        afis_criminal_check_api() {
            const TOKEN = localStorage.getItem('token');
            const AFIS_CRIMINAL_CHECK_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/afis_criminal_check_api/`;

            this.error = '';

            $.ajax({
                url: AFIS_CRIMINAL_CHECK_API,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    first_names: this.first_names,
                    surname: this.surname,
                    id_number: this.id_number,
                    phone_number: this.phone_number,
                    email_address: this.email_address
                }),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => Swal.showLoading(),
                    });
                },
                success: (response) => {
                    Swal.close();
                    Swal.fire({
                        icon: 'success',
                        title: 'Successful',
                        confirmButtonText: 'OK'
                    })
                    this.json_data = response.result;
                    $('#verification_results').modal('show');
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Something went wrong!';

                    if (error.responseJSON && error.responseJSON.message) {
                        error_message = error.responseJSON.message;
                        $('.error-message').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.error-message').text('').addClass('d-none');
                        }, 3000);
                    } else {
                        $('.error-message').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.error-message').text('').addClass('d-none');
                        }, 3000);
                    }
                }
            });
        },
    },

    mounted(){
        if (!validateToken(this.$router)) {
            return;
        }
    }
};