<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <title>Landing Page</title>
  <!-- Bootstrap CSS -->
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
</head>
<body>


<!-- Hero Section with Background Image -->
<section class="hero-section d-flex align-items-center justify-content-center text-white">
    <div class="bg-dark bg-opacity-50 p-5 text-center rounded">
      <h1 class="display-4 mb-4">Welcome to Our Landing Page</h1>
      <a class="btn btn-success btn-lg" @click="next_to_register">Get Started</a>
    </div>
  </section>

  <!-- Footer -->
  <footer class="bg-dark text-white text-center py-4">
    <p class="mb-0">&copy; 2024 TGPDC. All rights reserved.</p>
  </footer>

  
</body>
</html>