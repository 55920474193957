<div class="card shadow border-0 mt-4">
    <p class="fw-bold fs-8">Workflow Overview</p>
    <div class="row">
        <div class="col-md-4 col-sm-6  mb-2">
            <div class="card bg-light-green rounded-3 border-0">
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="text-xs mb-0 fw-bold text-green">Customers</p>
                            <h3 class="text-white mt-1">0</h3>
                        </div>
                        <div class="align-self-center text-center">
                            <span class="dot">
                            <i class="fa fa-users text-white mt-2"></i>
                            </span>
                        </div>
                    </div>
                    <div class="mt-2" align="right">
                        <a href ="#" class="mx-2 view text-xs" @click="redirect_to_view_customers">view </a>
                    </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-6 mb-2">
            <div class="card bg-light-green rounded-3 border-0">
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="text-xs mb-0 fw-bold text-green">Services</p>
                            <h3 class="text-white mt-1">0</h3>
                        </div>
                        <div class="align-self-center text-center">
                            <span class="dot">
                            <i class="fa fa-cog text-white mt-2"></i>
                            </span>
                        </div>
                    </div>
                    <div class="mt-2" align="right">
                        <a href ="#" class="mx-2 view text-xs">view </a>
                    </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-6 mb-2">
            <div class="card bg-light-green rounded-3 border-0">
                    <div class="d-flex justify-content-between">
                        <div>
                            <p class="text-xs mb-0 fw-bold text-green">Invoices</p>
                            <h3 class="text-white mt-1">0</h3>
                        </div>
                        <div class="align-self-center text-center">
                            <span class="dot">
                            <i class="fa fa-file text-white mt-2"></i>
                            </span>
                        </div>
                    </div>
                    <div class="mt-2" align="right">
                        <a href ="#" class="mx-2 view text-xs">view </a>
                    </div>
            </div>
        </div>
    </div>
</div>

