import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { allow_num,validate_id } from "@/utils/validations";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validateToken } from '@/utils/auth';

export default {
    name: 'ProofOfIncomeManual',
    mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
    data: () => ({ 
        id_number: '',
        account_number: '',
        bank_name: '',
        bank_statement: '',
        error_messages: {
            id_number: '',
            account_number: '',
            bank_name: '',
            bank_statement: ''
        }

    }),
    computed: {
        workflow_id() {
          return this.$store.getters.get_workflow_id;
        }
      },
    methods: {
      validate_fields() {
        this.error_messages = {};


        if (!this.id_number) {
          this.error_messages.id_number = "ID Number is required.";
      } else if (!validate_id(this.id_number)) {
          this.error_messages.id_number = "ID Number is invalid. Please provide a valid ID number.";
      }

        if (!this.account_number) {
            this.error_messages.account_number = "Bank Account Number is required.";
        }

        if (!this.bank_name) {
            this.error_messages.bank_name = "Please select a bank.";
        }

        if (!this.bank_statement) {
            this.error_messages.bank_statement = "Bank Statement is required.";
        }

        return Object.keys(this.error_messages).length === 0;
    },

      handle_input(event) {
        this.id_number = allow_num(event);
  
      },
        on_file_change(event) {
            const FILE = event.target.files[0];
            const MAX_SIZE = 5 * 1024 * 1024;
            if (FILE.size > MAX_SIZE) {
              event.target.value = null;
              Swal.fire({
                text: 'File is too large.',
                icon: 'warning',
                confirmButtonText: 'OK'
              });
              return;
            }
            if (FILE.type !== "application/pdf") {
              event.target.value = null;
              Swal.fire({
                text: 'Unsupported file type.',
                icon: 'warning',
                confirmButtonText: 'OK'
              });
              return;
            }
            this.bank_statement = FILE;
          },
      
        manual_proof_of_income() {
          if (!this.validate_fields()) {
            return;
        }
            const TOKEN = localStorage.getItem('token');
            const MANUAL_PROOF_OF_INCOME_URL = `${process.env.API_BASE_URL}/verify_api/personnel_verification/manual_proof_of_income_api/`;
        
            this.loading = true;
            this.error = '';
            const FORM_DATA = new FormData();
            FORM_DATA.append('id_number', this.id_number);
            FORM_DATA.append('workflow_id', this.workflow_id);
            FORM_DATA.append('bank_statement', this.bank_statement);
            FORM_DATA.append('bank_name', this.bank_name);
            FORM_DATA.append('account_number', this.account_number);
 
            $.ajax({
            method: "POST",
            url: MANUAL_PROOF_OF_INCOME_URL,
            data: FORM_DATA,
            processData: false, 
            contentType: false,
            headers: {
                Authorization: `Token ${TOKEN}`
            },
            beforeSend: () => {
                Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => Swal.showLoading(),
                });
            },
            success: (response) => {
                Swal.close();
                this.fetch_current_service();
                var status_message = response.status;

                if (status_message == "success") {
                this.fetch_current_service();
                } else if (status_message == "Results not found please contact your service provider") {
                Swal.fire({
                    icon: 'error',
                    text: "Our service is currently unavailable. Please contact your service provider and try again later",
                })
                }
            },
            error: () => {
              Swal.close();
            }
            });
        }
    },

    mounted() {
      if (!validateToken(this.$router)) {
        return;
      }
      this.get_customer_data();
    }
    
}