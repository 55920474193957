import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import ClientSidebar from '@/components/ClientSidebar/ClientSidebar.vue';
import NavBar from '@/components/NavBar/NavBar.vue';
import { validateToken } from '@/utils/auth';

export default {
  name: 'CreateAsset',
  components: {
    ClientSidebar,
    NavBar
  },
  data() {
    return {
      data_table: null,
      assets: [],
      asset_name: '',
      asset_description: '',
      asset_type: '',
      asset_types: [],
      active_workflows: [],
      asset_file: null,
      selected_asset_id: null,
      selected_workflow_id: null,
      created_asset_id: null
    };
  },
  methods: {
    initDataTable() {
      $('#assets_table').DataTable({
        paging: true,
        searching: true,
        ordering: true,
      });
    },

    fetch_assets() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_ASSETS_URL = `${BASE_URL}/verify_api/workflow_management_api/get_assets/`; 

      $.ajax({
        method: 'GET',
        url: GET_ASSETS_URL,
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
          this.assets = response; 
          this.$nextTick(() => {
            this.initDataTable(); 
          });
          Swal.close();
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON ? error.responseJSON.message : 'Failed to load assets.',
            confirmButtonText: 'OK'
          });
        },
      });
    },

    fetch_asset_types() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_ASSETS_TYPES_URL = `${BASE_URL}/verify_api/workflow_management_api/get_asset_types/`; 

      $.ajax({
        method: 'GET',
        url: GET_ASSETS_TYPES_URL,
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
          this.asset_types = response.asset_types;
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON ? error.responseJSON.message : 'An error occurred.',
          });
        }
      });
    },

    get_active_worfklows(){
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_ASSETS_TYPES_URL = `${BASE_URL}/verify_api/workflow_management_api/get_active_workflows_api/`; 

      $.ajax({
        method: 'GET',
        url: GET_ASSETS_TYPES_URL,
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
          this.active_workflows = response.active_workflows;
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON ? error.responseJSON.message : 'An error occurred.',
          });
        }
      });
    },

    open_assign_modal(asset_id) {
      this.selected_asset_id = asset_id;
      this.selected_workflow_id = '';
    },

    assign_to_workflow(asset_id, workflow_id){
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const ASSIGN_ASSET_TO_WORKFLOW_URL = `${BASE_URL}/verify_api/workflow_management_api/assign_asset_to_workflow_api/`; 
      const FORM_DATA = {
        asset_id: asset_id,
        workflow_id: workflow_id
    };

      $.ajax({
        method: 'POST',
        url: ASSIGN_ASSET_TO_WORKFLOW_URL,
        data: JSON.stringify(FORM_DATA),
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: () => {
          Swal.close();
          Swal.fire({
              icon: 'success',
              text: 'asset assigned to a workflow successfully',
              confirmButtonColor: '#59FF74',
              showConfirmButton: false,  // Hide the confirm button
              timer: 3000
              })
              .then(() => {
                  window.location.reload();
                  $('#add_service').modal('hide');
                });
          },
        error: (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.responseJSON.message,
            confirmButtonColor: '#59FF74',
            showConfirmButton: true
          });
      },
      });
    },

    handle_file_upload(event) {
      this.asset_file = event.target.files[0];
    },

    create_asset() {
      if (!this.asset_name || !this.asset_description || !this.asset_type) {
        Swal.fire({
          icon: 'error',
          text: 'All fields are required',
        });
        return;
      }

      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const CREATE_ASSET_URL = `${BASE_URL}/verify_api/workflow_management_api/create_asset_api/`;

      const form_data = new FormData();
      form_data.append('asset_name', this.asset_name);
      form_data.append('asset_description', this.asset_description);
      form_data.append('asset_type', this.asset_type);
      form_data.append('asset_file', this.asset_file);

      $.ajax({
        method: 'POST',
        url: CREATE_ASSET_URL,
        data: form_data,
        contentType: false,
        processData: false,
        headers: {
          Authorization: `Token ${TOKEN}`,

        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.fire({
            icon: 'success',
            text: response.message,
            showConfirmButton: false,  // Hide the confirm button
            timer: 3000
          }).then(() => {
            window.location.reload();
          })
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON ? error.responseJSON.message : 'An error occurred.',
          });
        },
      });
    },

    view_asset(asset_id) {
      this.$router.push({ name: 'view_asset', params: { id: asset_id } });
    }
  },

  mounted() {
    if (!validateToken(this.$router)) {
      return;
  }
    this.fetch_assets();
    this.fetch_asset_types();
    this.get_active_worfklows();
  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  },
};