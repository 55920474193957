
<div class="container-fluid mt-4">
    <div class="card mx-auto rounded-3 shadow border-0">
        <div class="card-header text-dark bg-white border-0">
            Would you like to upload terms and conditions or would you like to use Verify standard terms and conditions?
            <button type="button" class="btn btn-dark float-end text-white rounded-small shadow text-xxs px-4" data-bs-toggle="modal" data-bs-target="#terms_and_conditions">View Verify T's & C's</button>
        </div>
        <div class="card-body">
            <div class="row justify-content-center">
                <div class="col-md-8">
                       
                        <div class="text-center d-flex flex-column align-items-center">
                            <div class="title mb-3">
                                <p class="text fw-bold mt-2">Upload Terms and Conditions</p>
                                <small class="text-xs fw-bold mb-5"><span class="text-danger">*</span> Files supported: .pdf .docs</small>
                            </div>
                            <i class="fa fa-upload text fa-2x mb-3"></i>
                            <input type="file" accept=".pdf" @change="on_file_change" class="d-none" ref="file_input">
                            <button type="button" class="btn fw-bold bg-dark text-white rounded-pill small-upload-btn mb-2 mt-2 text-xs" @click="browse_file">Upload</button>
                            <div v-if="uploaded_file" class="mt-2 text-xs text-secondary fw-bold">
                                <p>Uploaded file: {{ uploaded_file.name }}</p>
                            </div>
                          
                        </div>
                        
                </div>
            </div>
            <div class="text-end mt-3">
                <button type="button" class="btn fw-bold bg-green rounded-small shadow secondary-text-dark text-xxs" @click="upload_terms_and_conditions">Next</button>
            </div>
        </div>
    </div>
</div>



<!-- View terms and conditions -->
<div class="modal fade" id="terms_and_conditions" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="terms_and_conditions" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content bg-white rounded-small">
            <div class="modal-header border-bottom-0">
                <div class="text-center">
                    <h5 class="modal-title" id="terms_and_conditions">The Good People Data Company</h5>
                </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="px-3 modal-info text-xs text-darkblue fw-light">
                    <p>Introduction to Verify's Ts & Cs:</p>
                    <p>
                        Welcome to the Terms and Conditions overview for Verify. These terms govern the relationship between users and verify, 
                        ensuring compliance with legal regulations and outlining the responsibilities of all parties involved. As part of the 
                        onboarding process, we will Verify the information that you provide, ensuring the accuracy and security of our platform. 
                        By using our platform, you agree to abide by these terms, which are designed to protect your rights, ensure fair usage, 
                        and maintain the integrity of our services.
                    </p>
                    <p>Compliance with Laws:</p>
                    <p> 
                        The Client agrees to comply with all relevant laws and regulations regarding the processing of information and the use of 
                        Verify services. This includes cooperation with any investigations required by law.
                    </p> 
        
                    <p>
                        User Details and Security:
                    </p>
                    <p>
                        The Client will provide user details as requested by Verify for the purpose of creating login credentials and onboarding. 
                        Verify reserves the right to take necessary actions to preserve network security and reliability. The Client must promptly 
                        report any security breaches related to Verify services.
                    </p>
                    <p>
                        Warranties:
                    </p>
                    
                    <p>
                        Both Parties warrant their validity and authority to enter into the Agreement. Verify does not provide warranties, 
                        and is not liable for certain losses except in cases of gross negligence or wilful misconduct
                    </p>
        
                    <p>Audit Rights:</p>
        
                    <p>
                        Verify reserves the right to audit the Client's compliance with the Agreement and may request information, data, or records 
                        for this purpose. The Client must cooperate with such audits and provide additional information if required.
                    </p>
        
                    <p>Limitation of Liability:</p>
        
                    <p>
                        Verify shall not be liable for certain losses unless due to gross negligence or wilful misconduct. The Client is solely liable 
                        for any claims arising from actions taken in reliance on information provided by Verify.
                    </p>
        
                    <p>Breach:</p>
        
                    <p>
                        Either Party must remedy any breach of the Agreement within seven days of notice. The Aggrieved Party may cancel the Agreement 
                        with twenty days' written notice in case of breach.
                    </p>
        
                    <p>Applicable Law and Jurisdiction:</p>
        
                    <p>
                        The platform is governed by the laws of the Republic of South Africa, and the Parties consent to the jurisdiction of the 
                        Magistrate’s Court of South Africa for legal proceedings.
                    </p>
        
                    <p>Disputes:</p>
        
                    <p>
                        Disputes shall be settled by mediation, and if necessary, by arbitration. Both Parties agree to cooperate in good faith to 
                        resolve disputes.
                    </p>
        
                    <p>Anti-Corruption Warranty:</p>
        
                    <p>
                        Verify is committed to compliance with anti-corruption laws, and the Client acknowledges their obligations under such laws. 
                        Breach of anti-corruption laws may result in penalties and legal action.
                    </p>
        
                    <p>Protection of Personal Information:</p>
                    <p>
                        Both Parties undertake to comply with the Protection of Personal Information Act (POPI) and agree on the handling and protection 
                        of personal information exchanged under the Agreement.
                    </p>
        
                    <p>Force Majeure:</p>
                    <p>
                        Neither Party shall be liable for failure to perform obligations due to circumstances beyond their control.
                    </p>
        
        
                    <p>General:</p>
                    <p>
                        In case of conflict between the platform and the law, the law shall prevail. Any modifications to the Agreement must be in writing and 
                        signed by both Parties. Indulgences granted by either Party shall not constitute waiver of rights. Successors-in-title shall be bound 
                        by the Agreement.
                    </p>
        
                    <p>Confidentiality:</p>
                    <p>
                        Both Parties agree to keep confidential all information obtained during the term of the Agreement and for a reasonable period thereafter.
                    </p>
        
                    <p>Intellectual Property:</p>
                    <p>
                        Any intellectual property developed during the term of the Agreement shall remain the property of the respective Party, unless otherwise 
                        agreed upon in writing.
                    </p>
        
                    <p>Counterparts:</p>
                    <p>
                        The Agreement may be executed in counterparts, each of which shall be deemed an original, but all of which together shall constitute one and 
                        the same instrument.
                    </p>
        
                    <p>Legal Fees:</p>
                    <p>
                        In the event of legal action arising from the Agreement, the prevailing Party shall be entitled to recover reasonable attorney fees 
                        and court costs.
                    </p>
        
                    <p>Conclusion:</p>
                    <p>
                        In conclusion, these Terms and Conditions form the foundation of our platform's operation, providing clarity, transparency, and legal protection 
                        for all users. By adhering to these terms, you contribute to a safe and trusted environment where everyone can benefit from our services. 
                        We appreciate your cooperation and look forward to serving you while upholding the highest standards of integrity and professionalism.
                    </p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn bg-green secondary-text-dark text-xs shadow" data-bs-dismiss="modal" @click="use_verify_terms_and_conditions">Use</button>
            </div>
            
        </div>
    </div>
</div>
