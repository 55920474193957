<div class="container-fluid bg-white vh-100 p-0">
    <div class="text-center mt-5 mb-1">
    <img :src="$clientLogo" width="250px" />
    </div>

    <div class="mt-1">
    <nav aria-label="breadcrumb" class="w-50 mx-auto">
        <ol class="breadcrumb rounded-3 justify-content-center bg-dark text-white p-2 mb-4">
        <li class="breadcrumb-item active cursive-text text-white" aria-current="page">
            Let's get you authenticated....
        </li>
        </ol>
    </nav>
    
    <div class="text-center">
        <small class="text-dark fw-bold mb-5 mt-4 w-50 mx-auto">
            Please select the applicable correct answer from the options below:
        </small>
    </div>


    <div class="mt-5 mb-3 w-50 mx-auto">
        <div v-for="(question, question_index) in results" :key="question_index">
            <p class="text-xs fw-bold">Q. {{ question.question }}</p>
            
            <div class="ml-md-3 ml-sm-3 pl-md-2 pt-sm-0 pt-3 text-xxs" id="options">
              <!-- Single answer (radio buttons) -->
              <div v-if="question.required_no_of_answers === 1" class="options">
                <div v-for="answer in question.answers" :key="answer.answer_id" class="form-check mb-2">
                  <input
                    class="form-check-input me-2"
                    type="radio"
                    :name="'radio' + question.question_id" 
                    :id="'radio' + answer.answer_id" 
                    :value="answer.answer.answer"
                    :data-question-id="question.question_id"
                    :data-answer-id="answer.answer_id">
                  <label class="form-check-label" :for="'radio' + answer.answer_id"> 
                    {{ answer.answer.answer }}
                  </label>
                </div>
              </div>
        
              <!-- Multiple answers (checkboxes) -->
              <div v-else class="checkbox-options">
                <div v-for="answer in question.answers" :key="answer.answer_id" class="form-check mb-2">
                    <input
                      class="form-check-input me-2"
                      type="checkbox"
                      :name="'checkbox' + question.question_id" 
                      :id="'checkbox' + answer.answer_id" 
                      :value="answer.answer.answer"
                      :data-question-id="question.question_id"
                      :data-answer-id="answer.answer_id">
                    <label class="form-check-label" :for="'checkbox' + answer.answer_id"> 
                      {{ answer.answer.answer }}
                    </label>
                </div>
              </div>
            </div>
          </div>
    </div>

    <div class="text-center mb-3 mt-3">
        <button @click="submit_answers" class="btn bg-green text-center secondary-text-dark rounded-3 fw-bold text-xs">Continue</button>
    </div>
    </div>
</div>

  