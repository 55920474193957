<div class="container-fluid bg-white vh-100 p-0">
    <ClientLogo />
    <BreadCrumb label="Company Bank Account Verification" />

    <div class="d-flex justify-content-center align-items-center min-vh-50">
        <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
            <div class="form-group mt-3">
                <label class="fw-bold text-xs text-start d-block">Email Address<span class="text-danger">*</span></label>
                <input
                    type="email"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    id="email_address"
                    v-model="email_address"
                    required
                />
                <small v-if="error_messages.email_address" class="form-text text-danger text-xs fw-bold">
                    {{ error_messages.email_address }}
                </small>

                <label class="fw-bold text-xs text-start d-block mt-2">Company Name<span class="text-danger">*</span></label>
                <input
                    type="text"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    id="company_name"
                    v-model="company_name"
                    required
                />
                <small v-if="error_messages.company_name" class="form-text text-danger text-xs fw-bold">
                    {{ error_messages.company_name }}
                </small>

                <label class="fw-bold text-xs text-start d-block mt-2">Company Registration No. <span class="text-danger">*</span></label>
                <input
                    type="text"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    id="company_registration"
                    v-model="company_registration"
                    @input="validate_reg_number"
                    required
                />
                <small v-if="error_messages.company_registration" class="form-text text-danger text-xs fw-bold">
                    {{ error_messages.company_registration }}
                </small>

                <label class="fw-bold text-xs text-start d-block mt-2">Bank Name <span class="text-danger">*</span></label>
                <select
                    v-model="bank"
                    class="form-select form-select-sm mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                >
                    <option value="" disabled hidden>Select Bank</option>
                    <option v-for="(bank, index) in banks" :key="bank.id">
                        {{ bank.bank_name }}
                    </option>
                </select>
                <small v-if="error_messages.bank" class="form-text text-danger text-xs fw-bold">
                    {{ error_messages.bank }}
                </small>

                <label class="fw-bold text-xs text-start d-block mt-2">Account Type<span class="text-danger">*</span></label>
                <select
                    v-model="account_type"
                    class="form-select form-select-sm mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                >
                    <option value="" disabled hidden>Select Account type</option>
                    <option v-for="(account_type, index) in account_types" :key="account_type.id">
                        {{ account_type.account_type }}
                    </option>
                </select>
                <small v-if="error_messages.account_type" class="form-text text-danger text-xs fw-bold">
                    {{ error_messages.account_type }}
                </small>

                <label class="fw-bold text-xs text-start d-block mt-2">Account Number <span class="text-danger">*</span></label>
                <input
                    type="text"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    id="account_number"
                    v-model="account_number"
                    required
                />
                <small v-if="error_messages.account_number" class="form-text text-danger text-xs fw-bold">
                    {{ error_messages.account_number }}
                </small>


                <div class="text-center mt-4">
                    <button
                        class="btn bg-green btn-sm rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
                        @click.prevent="bank_account_verification"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

