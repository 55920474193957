import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import Swal from 'sweetalert2';
import { validateToken } from '@/utils/auth';

export default {
  name: 'WorkflowServices',
  data: () => ({
    services: [],
    checked_services: new Set(),
    loading: false
  }),
  computed: {
    project_id() {
      return this.$store.getters.get_project_id;
    },
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },
  methods: {
    get_services_in_project() {
      const TOKEN = localStorage.getItem('token');
      const GET_PROJECT_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/project_services_api/${this.project_id}/${this.workflow_id}`;
      
      $.ajax({
        url: GET_PROJECT_SERVICES_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          // Initialize services with is_checked defaulting to false
          this.services = Array.isArray(response.all_services) ? response.all_services.map(service => ({
            ...service,
            is_checked: false // Default to false if not provided by the API
          })) : [];

          // Update `is_checked` state for each service based on current status
          this.update_service_status(); // Update status for all services

          this.loading = false; // Set loading to false after data is loaded
          this.$nextTick(() => {
            if ($.fn.dataTable.isDataTable('#workflow-service-table')) {
              $('#workflow-service-table').DataTable().destroy();
            }
            $('#workflow-service-table').DataTable({
              responsive: true
            });
          });
        },
        error: (error) => {
          this.loading = false; // Ensure loading is false even if there's an error
          let error_message = 'Error Getting Service(s)';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    update_service_status() {
      // Function to get the status of all services and update their `is_checked` state
      const TOKEN = localStorage.getItem('token');
      const GET_CHECKED_SERVICE_STATUS_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_workflow_services/${this.workflow_id}`;
    
      $.ajax({
        url: GET_CHECKED_SERVICE_STATUS_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        success: (response) => {
          if (response.status === 'success') {
            // Create a map of project_service_id to is_selected
            const serviceSelectionMap = response.data.reduce((map, item) => {
              map[item.project_service_id] = item.is_selected;
              return map;
            }, {});

            // Update the `is_checked` state for each service
            this.services.forEach(service => {
              if (serviceSelectionMap[service.id] !== undefined) {
                service.is_checked = serviceSelectionMap[service.id];
                if (service.is_checked) {
                  this.checked_services.add(service.id);
                }
              }
            });
          }
        },
        error: (error) => {
          let error_message = 'Error Getting Checked Service Status';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });    
    },

    handle_service_check(service_id, isChecked) {
      const TOKEN = localStorage.getItem('token');
      const SAVE_PROJECT_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/select_workflow_services/${this.workflow_id}`;

      const FORM_DATA = {
        workflow_id: this.workflow_id,
        project_service_id: service_id,
        is_selected: isChecked
      };

      $.ajax({
        url: SAVE_PROJECT_SERVICES_API,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        success: (response) => {
          let status = response.status;

          if (status === 'success') {
            // Update the is_checked state for the corresponding service
            const service = this.services.find(service => service.id === service_id);
            if (service) {
              service.is_checked = isChecked; // Update the state to reflect the user's action
              if (isChecked) {
                this.checked_services.add(service_id);
              } else {
                this.checked_services.delete(service_id);
              }
            }
          }
        },
        error: (error) => {
          let error_message = 'Error Saving Service';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },
    
    check_if_no_service_selected() {
      if (this.checked_services.size === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'You must select at least one service before proceeding.',
          confirmButtonText: 'OK'
        });
        return false;
      }
      return true;
    },

    navigate_to_client_service() {
      this.$router.push({ name: 'client_services' });
    },

    route_to_rules() {
      if (this.check_if_no_service_selected()) {
        this.$router.push({ name: 'arrange_workflow' });
      }
    }
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_services_in_project();
  }
};

