<div class="client-sidebar shadow">
    <div class="sidebar-brand text-center mt-3">
        <img :src="$clientLogo" alt="Logo" class="sidebar-logo img-fluid" />
      </div>
    
    <hr class="fw-bold mb-4 mt-1 mx-4 text-dark">
    <ul v-if="user_info.user_role == 'SystemAdmin'" class="nav flex-column mb-auto">
       <span class="text-xxs fw-bold p-0">Dashboard</span>
        <li class="nav-item mt-0 mb-0 p-2">
            <router-link to="/system_admin/admin_dashboard"
             class="nav-link" active-class="active">
                <i class="fas fa-tachometer-alt me-2 green-color"></i>
                Dashboard
            </router-link>
        </li>
        <span class="text-xxs fw-bold p-0 mt-1">Management</span>
        <li class="nav-item mt-0 mb-0 p-2">
            <router-link to="/system_admin/client_applications"
             class="nav-link " active-class="active">
                <i class="fas fa-file-alt me-2 green-color"></i>
                Applications
            </router-link>
        </li>
        <li class="nav-item mt-0 mb-0 p-2">
            <router-link to="/system_admin/users" class="nav-link"
            active-class="active">
                <i class="fas fa-users me-2 green-color"></i>
                Users
            </router-link>
        </li>
        <li class="nav-item mt-0 mb-0 p-2">
            <router-link to="/system_admin/service_applications" class="nav-link"
            active-class="active">
                <i class="fas fa-users me-2 green-color"></i>
                Instant Verify 
                <div class="p-1">Requests</div>
            </router-link>
        </li>
    </ul>

    <ul class="nav flex-column me-1 text-start" v-if="(user_info.user_role == 'ClientAdmin' || user_info.user_role == 'Client') && show_project_dashboard_tabs">
        <li class="nav-item">
          <router-link 
          :to="{ name: 'project_dashboard' }" 
            class="nav-link fw-bold" 
            active-class="active">
            <img :src="$clientSideBarIcon.Dashboard" class="icon-small me-2 green-color" />Dashboard
          </router-link>
        </li>
        <li class="nav-item">
          <router-link 
            :to="{ name: 'service_dashboard' }" 
            class="nav-link fw-bold " 
            active-class="active">
            <img :src="$clientSideBarIcon.Services" class="icon-small me-2 green-color" />Services
          </router-link>
        </li>
        <li class="nav-item">
          <router-link 
          :to="{ name: 'create_asset' }"
            class="nav-link fw-bold" 
            active-class="active">
            <img :src="$clientSideBarIcon.Assets" class="icon-small me-2" />Assets
          </router-link>
        </li>
    </ul>
  
    <ul class="nav flex-column me-1 text-start" v-if="(user_info.user_role == 'ClientAdmin' || user_info.user_role == 'Client') && show_project_tabs">
      <span class="text-xxs fw-bold p-0">Management</span>
      <li class="nav-item">
        <router-link 
          :to="{ name: 'question_management' }" 
          class="nav-link fw-bold" 
          active-class="active">
          <img :src="$clientSideBarIcon.Questions" class="icon-small me-2 green-color" />Questions
        </router-link>
      </li>
      <li class="nav-item">
        <router-link 
          :to="{ name: 'form_management' }" 
          class="nav-link fw-bold" 
          active-class="active">
          <img :src="$clientSideBarIcon.Forms"  class="icon-small me-2 green-color" />Forms
        </router-link>
      </li>
      <li class="nav-item">
        <router-link 
          :to="{ name: 'template_management' }" 
          class="nav-link fw-bold" 
          active-class="active">
          <img :src="$clientSideBarIcon.Templates" class="icon-small me-2 green-color" />Templates
        </router-link>
      </li>
      <li v-if="user_info.user_role == 'ClientAdmin'" class="nav-item mt-0 mb-0 p-2">
        <router-link
          :to="{ name: 'client_admin' }" 
          class="nav-link fw-bold"
          active-class="active">
          <i class="fas fa-users me-2 green-color"></i>
          Users
        </router-link>
      </li>
    
      <template v-if="$route.name === 'project_table'">
        <span class="fw-bold text-xxs p-0">Services</span>
        <li class="nav-item">
          <router-link 
            :to="{ name: 'instant_verify' }"
            class="nav-link fw-bold"
            active-class="active">
            <img :src="$clientSideBarIcon.InstantVerify" class="icon-small me-2 green-color" />Instant Verify
          </router-link>
        </li>
      </template>
    </ul>

    <ul class="nav flex-column me-1 text-start" v-if="(user_info.user_role == 'ClientAdmin' || user_info.user_role == 'Client') && show_instant_verify_tabs">
      <li class="nav-item">
        <router-link
          :to="{ name: 'instant_verify' }"
          class="nav-link fw-bold" 
          active-class="active">
            <i class="fas fa-tachometer-alt me-2 green-color"></i>
            Dashboard
        </router-link>
      </li>
      <li class="nav-item">
        <router-link 
          :to="{ name: 'instant_services' }"
          class="nav-link fw-bold "
          active-class="active">
          <img :src="$clientServiceIcon.Services" class="icon-small me-2 green-color" />Services
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'services' }"
          class="nav-link fw-bold"
          active-class="active">
          <img :src="$clientSideBarIcon.InstantVerify" class="icon-small me-2" />Your Services
        </router-link>
      </li>
    </ul>
    <ul>
      <div class="logout">
          <li  class="text-decoration-none nav-link">
              <a href="#" class="text-xs fw-bold" @click="route_to"><i class="fas fa-sign-out-alt me-2 green-color"></i>Logout</a>
          </li>
      </div>
    </ul>
</div>
