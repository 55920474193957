import NavBar from '@/components/NavBar/NavBar.vue';
import { validateToken } from '@/utils/auth';

export default {
    name: 'ViewService',
    pageTitle: 'View Service', 
    components: {
        NavBar
    },
    data: () => ({
        service_description: {},
        description: '',
        service_inputs: [],
        service_outputs: [],
        service_cost: 0.00,
    }),
    methods: {
    get_service_description() {
        const TOKEN = localStorage.getItem('token');
        const service_id = this.$route.params.id;

        const GET_CUSTOMERS = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_service_descprition/${service_id}`;
  
        $.ajax({
          url: GET_CUSTOMERS,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: (response) => {
            Swal.close();
            // Map and format the response data
            this.service_description = response.service_description[0];
            this.description = this.service_description.service_details[0].service_description;
            this.service_inputs = JSON.parse(this.service_description.service_details[0].service_input.replace(/'/g, '"'));
            this.service_outputs = JSON.parse(this.service_description.service_details[0].service_output.replace(/'/g, '"'));
            this.service_cost = this.service_description.service_details[0].service_cost;
          },
          error: () => {
            Swal.close();
          }
        });
      },
    closeService() {
        this.$router.push({ name: 'service_dashboard' });
    }
    },
    mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_service_description();
    }
  }