<div class="container-fluid bg-white vh-100 p-0 d-flex flex-column align-items-center">
    <div class="text-center mt-5 mb-2">
      <img :src="$clientLogo" width="200px" />
    </div>
  
    <div class="text-center mt-1 w-100">
      <nav aria-label="breadcrumb" class="w-50 mx-auto">
        <ol class="breadcrumb rounded-3 justify-content-center bg-dark text-white p-2">
          <li class="breadcrumb-item active cursive-text text-white" aria-current="page">
            Proof Of Income Manual
          </li>
        </ol>
      </nav>
    </div>
  
    <div class="d-flex flex-column align-items-center w-50 mt-2">

      <!-- Id number-->
      <div class="form-group mb-4 w-75">
        <label for="id_number" class="fw-bold text-xs text-start d-block">ID Number <span class="text-danger">*</span></label>
        <input
          v-model="id_number"
          type="text"
          class="form-control rounded-small shadow-sm w-100"
          id="id_number"
          placeholder="Enter an ID Number"
          @input="handle_input"
          maxlength="13"
          required
        />
        <small v-if="error_messages.id_number" class="form-text text-danger">
          {{ error_messages.id_number }}
        </small>
      </div>

      <!-- Bank Account Number -->
      <div class="form-group mb-4 w-75">
        <label for="account_number" class="fw-bold text-xs text-start d-block">Bank Account Number <span class="text-danger">*</span></label>
        <input
          v-model="account_number"
          type="text"
          class="form-control rounded-small shadow-sm w-100"
          id="account_number"
          placeholder="e.g., 13XXXXX78"
          required
        />
        <small v-if="error_messages.account_number" class="form-text text-danger">
          {{ error_messages.account_number }}
        </small>
      </div>

      <div class="form-group mb-4 w-75">
        <label class="fw-bold text-xs text-start d-block">Select Bank<span class="text-danger">*</span></label>
        <select v-model='bank_name' class="form-select rounded-small text-xs" required>
          <option value="" selected disabled hidden>Select Your Bank</option>
          <option value="absa">Absa</option>
          <option value="capitec">Capitec</option>
          <option value="fnb">Fnb</option>
          <option value="investec">Investec</option>
          <option value="nedbank">Nedbank</option>
          <option value="standard_bank">Standard Bank</option>
          <option value="tyme_bank">Tyme</option>
        </select>
        <small v-if="error_messages.bank_name" class="form-text text-danger">
          {{ error_messages.bank_name }}
        </small>
      </div>
  
      <!-- Document Upload -->
      <div class="form-group mb-4 w-75">
        <label for="bank_statement" class="fw-bold text-xs text-start d-block">Upload Document (Bank Statement) <span class="text-danger">*</span></label>
        <input
        type="file"
        class="form-control rounded-small shadow-sm w-100"
        id="bank_statement"
        accept="application/pdf"
        @change="on_file_change($event)"
        ref="bank_statement"
        required
        />
        <small v-if="error_messages.bank_statement" class="form-text text-danger">
          {{ error_messages.bank_statement }}
        </small>
     </div>
  
      <!-- Submit Button -->
      <div class="text-center mb-3 mt-4">
        <button
          class="btn btn-sm bg-green text-dark rounded-small fw-bold secondary-text-dark shadow-sm"
          @click="manual_proof_of_income">
          Continue
        </button>
      </div>
    </div>
  </div>
  