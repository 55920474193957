<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-md-3">
      <div class="card card-bg shadow rounded-3  border-0 p">
          <div class="card-body">
              <div class="d-flex justify-content-between px-md-1">
                  <div>
                  <h6 >Applications</h6>
                  <p class="mb-0 sub_heading text-xsm fw-bold">
                    {{ applications }}
                  </p>
                  </div>
                  <div class="align-self-center rounded-circle p-2 shadow bg-green">
                    <i class="fa fa-cogs fa-lg me-auto secondary-text-dark"></i>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card card-bg shadow rounded-3 border-0">
          <div class="card-body">
              <div class="d-flex justify-content-between px-md-1">
                  <div>
                    <h6>Clients</h6>
                  <p class="mb-0 sub_heading text-xsm fw-bold">
                    {{ client }}
                  </p>
                  </div>
                  <div class="align-self-center rounded-circle p-2 shadow bg-green">
                    <i class="fas fa-users fa-lg  secondary-text-dark"></i>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card card-bg shadow rounded-3 border-0 mb-2">
          <div class="card-body">
              <div class="d-flex justify-content-between px-md-1">
                  <div>
                    <h6>Client Admins</h6>
                  <p class="mb-0 sub_heading text-xsm fw-bold">
                    {{client_admin}}
                  </p>
                  </div>
                  <div class="align-self-center rounded-circle p-2 shadow bg-green">
                    <i class="fas fa-user-shield fa-lg secondary-text-dark"></i>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card card-bg shadow rounded-3 border-0 mb-2">
          <div class="card-body">
              <div class="d-flex justify-content-between px-md-1">
                  <div>
                    <h6>Admins</h6>
                  <p class="mb-0 sub_heading text-xsm fw-bold">
                    {{admins}}
                  </p>
                  </div>
                  <div class="align-self-center rounded-circle p-2 shadow bg-green">
                    <i class="fas fa-user-shield fa-lg secondary-text-dark"></i>
                  </div>
              </div>
          </div>
      </div>
    </div>

</div>
</div>