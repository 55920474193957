export default {
    name:'UserInfoFields',
    props: {
      first_name: { type: String, default: '' },
      last_name: { type: String, default: '' },
      email_address: { type: String, default: '' },
      phone_number: { type: String, default: '' }
    },
    data() {
      return {
        // Initialize local data based on props
        first_name: this.first_name,
        last_name: this.last_name,
        email_address: this.email_address,
        phone_number: this.phone_number
      };
    },
    watch: {
      // Watch for changes in data and emit updated values
      first_name(value) { this.$emit('update:first_name', value); },
      last_name(value) { this.$emit('update:last_name', value); },
      email_address(value) { this.$emit('update:email_address', value); },
      phone_number(value) { this.$emit('update:phone_number', value); }
    }
  };