<h5 class="text-s text-center"><strong>AFIS Criminal Check</strong></h5>
<form @submit.prevent="afis_criminal_check_api">
    <div class="row mt-2 mb-2">
        <div class="text-start">
            <label class="form-label text-xs">First Names <span class="text-danger fw-bold">*</span></label>
            <input v-model="first_names" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required
                placeholder="eg. Joe">
        </div>
    </div>
    <div class="row mt-2 mb-2">
        <div class="col-md-6 text-start">
            <label class="form-label text-xs">Last Name <span class="text-danger fw-bold">*</span></label>
            <input v-model="surname" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required
                placeholder="eg. Doe">
        </div>
        <div class="col-md-6 text-start">
            <label class="form-label text-xs">ID Number <span class="text-danger fw-bold">*</span></label>
            <input v-model="id_number" type="text" class="form-control text-xs rounded-small p-3 shadow-sm"
                required minlength="13" maxlength="13">
        </div>
    </div>

    <div class="row mt-2 mb-2">
        <div class="col-md-6 text-start">
            <label class="form-label text-xs">Phone Number <span class="text-danger fw-bold">*</span></label>
            <input v-model="phone_number" type="text" class="form-control text-xs rounded-small p-3 shadow-sm"
                placeholder="eg. 0612345678" required>
        </div>
        <div class="col-md-6 text-start">
            <label class="form-label text-xs">Email Address<span class="text-danger fw-bold">*</span></label>
            <input v-model="email_address" type="text" class="form-control text-xs rounded-small p-3 shadow-sm"
                required placeholder="eg. joe@gmail.com">
        </div>
    </div>
    <div class="col text-end">
        <button type="submit" class="btn bg-green btn-sm text-xs shadow rounded-small mt-2 fw-bold">Submit</button>
    </div>
</form>

<div class="modal" tabindex="-1" id="verification_results">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Verification Result</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div v-if="Object.keys(json_data).length > 0">
                    <pre>Your information has been submitted. You will be notified once the results are available for retrieval. Thank you for using our services!</pre>
                </div>
            </div>
            <div class="modal-footer">
                <router-link :to="{ name: 'services' }">
                    <button class="btn bg-green btn-sm text-xs text-end  rounded-small mt-3"
                        data-bs-dismiss="modal">Back</button>
                </router-link>
            </div>
        </div>
    </div>
</div>