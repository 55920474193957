<header>
  <nav class="navbar-landing">
    <img src="@/assets/images/Wizzit-Logo-Red.png" alt="Logo" class="logo sidebar-logo img-fluid" />
  </nav>
</header>

<section class="hero-section-landing">
  <img src="@/assets/images/New_wizzit_image.png" alt="Banner" class="hero-image" />
  <div class="hero-content">
    <h1 class="hero-title">A New Journey Begins.</h1>
    <button class="hero-button fw-bold" @click="next_to_register">Get Started</button>
  </div>
</section>