import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import Swal from 'sweetalert2';
import AdminSidebar from '@/components/AdminSidebar/AdminSidebar.vue';
import AdminNavBar from '@/components/AdminNavBar/AdminNavBar.vue';
import { validateToken } from '@/utils/auth';

export default {
  name: 'ClientApplications',
  components: {
    AdminNavBar,
    AdminSidebar
  },
  data: () => ({
    currentTab: localStorage.getItem('applicationsTab') || 'Pending',
    data: {
      Pending: [],
      Approved: [],
      Deactivated: [],
      Declined: []
    },
  }),
  computed: {
    filteredData() {
      return this.data[this.currentTab];
    }
  },
  methods: {
    switchTab(tab) {
      this.currentTab = tab;
      localStorage.setItem('applicationsTab', tab); 
      this.$nextTick(() => {
        this.initDataTable();
      });
    },
    getApplications() {
      const TOKEN = localStorage.getItem('token');
      const GET_USER_APPLICATION_DATA_API = `${process.env.API_BASE_URL}/verify_api/verify_admin_api/get_user_applications_api/`;

      $.ajax({
        url: GET_USER_APPLICATION_DATA_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();

          ['pending_table', 'approved_table', 'deactivated_table', 'declined_table'].forEach(status => {
            response[status] = response[status].map(application => {
              application.date_created = application.date_created.split(' ')[0]; // Keep only the date part
              return application;
            });
          });

          this.data.Pending = response.pending_table || [];
          this.data.Approved = response.approved_table || [];
          this.data.Declined = response.declined_table || [];
          this.data.Deactivated = response.deactivated_table || [];

          this.$nextTick(() => {
            this.initDataTable(); // Initialize DataTable after data is loaded
          });
        },
        error: () => {
          Swal.close();
          Swal.fire({
            text: 'Failed to load data',
            icon: 'error',
          });
        }
      });
    },
    initDataTable() {
      this.$nextTick(() => {
        if ($.fn.dataTable.isDataTable('#applications-table')) {
          $('#applications-table').DataTable().destroy();
        }
        $('#applications-table').DataTable({
          responsive: true
        });
      });
    },
    view_pending_application(user_id) {
      window.location.href = this.$router.resolve({ name: 'view_pending_application', params: { user_id: user_id } }).href;
    },
    view_approved_application(user_id) {
      window.location.href = this.$router.resolve({ name: 'view_approved_application', params: { user_id: user_id } }).href;
    },
    view_deactivated_application(user_id) {
      window.location.href = this.$router.resolve({ name: 'view_deactivated_application', params: { user_id: user_id } }).href;
    },
    view_declined_application(user_id) {
      window.location.href = this.$router.resolve({ name: 'view_declined_application', params: { user_id: user_id } }).href;
    },
  },
  mounted() {
    if (!validateToken(this.$router)) {
        return;
    }
    this.getApplications();
  }
};
