export default {
    name: 'AdminNavBar',

    data: () => ({
        user_info: {},
      }),
      methods: {
        get_user_information() {
            const TOKEN = localStorage.getItem('token');
            const GET_USER_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_user_api/`;
        
            $.ajax({
                url: GET_USER_INFORMATION_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                success: (response) => {
                    Swal.close();
                    this.user_info = response.data;
                },
                error: () => {
                    Swal.close();
                }
            });
        },

        getInitial(name) {
            if (typeof name == 'string' && name.length > 0) {
                return name.charAt(0).toUpperCase();
            } else {
                return '';
            }
        },
        route_to(){
            this.$router.push('/');
        },
        go_back() {
          if (window.history.length > 1) {
            this.$router.go(-1);
          } else {
            // Redirect to a default page if there's no history
            this.$router.back();
          }
        }
      },
      mounted() {
        this.get_user_information();
    }
}