import GetQuestions from '@/components/GetQuestions/GetQuestions.vue';
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";

export default {

    name: 'UserOtp',
    mixins: [MAIN_CONTROLLER],
    components: {
        GetQuestions,
    },
    data: () => ({
        otp: '',
        responses: [],
        client_admin_id: sessionStorage.getItem('client_admin_id'),
        show_resend_message: false,
        failed_resend_message: false
        }),
    mounted() {
        const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
        if (GET_CLIENT_ID == "Customer" ) {
            this.store_responses();
        }
        this.send_otp();
    },
    methods: {
        store_responses(dynamic_fields = []) {
            this.responses = dynamic_fields;
        },
        // Method to handle redirects based on registration status
        handleRegistrationRedirect(response) {
            const role = response.role.toUpperCase();
            const status = response.status;

        
            switch (role) {
                case 'CUSTOMER': {
                    const workflow_id = response.workflow_id;
                    if (workflow_id !== null) {
                        this.$store.dispatch('update_workflow_id', workflow_id);
                        this.fetch_current_service();
                    }
                    else{
                        this.$router.push({ name: 'assigned_workflows'});
                    }
                    break;
                }
                case 'CLIENTADMIN':
                    this.handleClientRedirect(status);
                    break;
                case 'SYSTEMADMIN':
                    this.$router.push('system_admin/admin_dashboard');
                    break;
                case 'DIRECTOR': {
                    switch (status) {
                        case 'no_consent':
                            this.$router.push('/director_consent');
                            break;
                        case 'not_verified':
                            this.$router.push('/facial_biometrics_verification');
                            break;
                        case 'no_proof_of_address':
                            this.$router.push('/upload_proof_of_address');
                            break;
                        default:
                            this.$router.push('/thank_you_page');
                    }
                    break;
                }
                case 'CLIENT':
                    this.$router.push('client/project_table');
                    break;
                default:
                    this.$router.push('/company_pending');
            }
        },
        
        handleClientRedirect(status) {
            switch (status) {
                case 'no_profile_info':
                    this.$router.push('/id_verification');
                    break;
                case 'not_verified':
                    this.$router.push('/facial_biometrics');
                    break;
                case 'no_company_profile':
                    this.$router.push('/company_verification');
                    break;
                case 'failed_verification':
                case 'pending':
                case 'declined':
                    this.$router.push('/company_pending');
                    break;
                case 'approved':
                    this.$router.push('client/project_table');
                    break;
                default:
                    this.$router.push('client/project_table');
            }
        },
        validate_input(field_name) {
            let input_value = this[field_name];
            input_value = input_value.replace(/\D/g, '');
            this[field_name] = input_value;
        },

        send_otp() {
            const TOKEN = localStorage.getItem('token');
            const OTP_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_otp_api/`;
            $.ajax({
                url: OTP_URL,
                method: 'GET',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                success: () => {
                    this.$router.push('/user_otp');
                },
                error: () => {
                    this.$router.push('/user_otp');
                }
            });
        },

        resend_otp() {
            const TOKEN = localStorage.getItem('token')
            const OTP_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_otp_api/`;
            $.ajax({
                url: OTP_URL,
                method: 'GET',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                    });
                },
                success: () => {
                    Swal.close();
                    this.show_resend_message = true;
                    setTimeout(() => {
                        this.show_resend_message = false;
                    }, 5000);
                    this.$router.push('/user_otp');
                },
                error: () => {
                    Swal.close();
                    this.failed_resend_message = true;
                    setTimeout(() => {
                        this.failed_resend_message = false;
                    }, 5000);
                    this.$router.push('/user_otp');
                }
            });
        },

        submit_otp() {

            if (!this.otp.trim()) {
                $('.otp-message').removeClass('d-none');
                setTimeout(() => {
                    $('.otp-message').addClass('d-none');
                }, 3000);
                return false;
            }

            const is_responses_valid = this.$refs.get_questions.validate_fields();
            if (!is_responses_valid) {
                return; 
            }
            const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
            const TOKEN = localStorage.getItem('token');
            const OTP_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/save_otp_api/`;

            const FORM_DATA = {
                client_admin_id: GET_CLIENT_ID,
                otp: this.otp ,
                responses: this.responses.map(field => ({
                    service_id: field.service_id,
                    question_id: field.question_id,
                    value: field.value
                }))
              };
            
            // Check if any response includes a file
            const has_file = this.responses.some(field => field.has_file);

            let data_to_send;
            if (has_file) {
                // If there's a file, create a FormData instance
                const form_data = new FormData();
                form_data.append('client_admin_id', GET_CLIENT_ID);
                form_data.append('otp', this.otp);

                // Append responses, handling files if present
                this.responses.forEach((field, index) => {
                    form_data.append(`responses[${index}][service_id]`, field.service_id);
                    form_data.append(`responses[${index}][question_id]`, field.question_id);
                    if (field.has_file) {
                        form_data.append(`responses[${index}][uploaded_file]`, field.value);
                    } else {
                        form_data.append(`responses[${index}][value]`, field.value);
                    }
                });

                data_to_send = form_data;
            } else {
                data_to_send = JSON.stringify(FORM_DATA);
            }

            // Determine content type based on the presence of files
            const content_type = has_file ? false : 'application/json';

            $.ajax({
                url: OTP_URL,
                method: 'POST',
                data: data_to_send,
                processData: !has_file,  // Do not process data if using FormData
                contentType: content_type,
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: () => {
                    Swal.close();
                    localStorage.setItem('user_state', 'otp_verified');
                    const CHECK_PROCESS_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/user_redirect_api/`;
                    
                    $.ajax({
                        url: CHECK_PROCESS_URL,
                        method: 'GET',
                        contentType: 'application/json',
                        headers: {
                            Authorization: `Token ${TOKEN}`
                        },
                        success: (response) => {
                            this.handleRegistrationRedirect(response);
                        },
                        error: () => {
                            Swal.close();
                            let error_message = 'There was an error while redirecting';
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: error_message,
                                confirmButtonText: 'OK'
                            });
                        }
                    })
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Error verifying OTP';

                    if (error.responseText.trim()) {
                        const ERROR_DATA = JSON.parse(error.responseText);
                        if (ERROR_DATA && ERROR_DATA.data) {
                            error_message = ERROR_DATA.data;
                            $('.error-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.error-message').text('').addClass('d-none');
                            }, 3000);
                        }
                    }
                }
            });
        }
    }
};