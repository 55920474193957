<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-md-4">
      <div class="card card-bg shadow rounded-3  border-0 p">
          <div class="card-body">
              <div class="d-flex justify-content-between px-md-1">
                  <div>
                  <h6 >Forms</h6>
                  <p class="mb-0 sub_heading text-xsm fw-bold number">
                   
                  </p>
                  </div>
                  <div class="align-self-center rounded-circle p-2 shadow bg-green">
                    <i class="fa fa-cogs fa-lg me-auto secondary-text-dark"></i>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-bg shadow rounded-3 border-0">
          <div class="card-body">
              <div class="d-flex justify-content-between px-md-1">
                  <div>
                    <h6>Services</h6>
                  <p class="mb-0 sub_heading text-xsm fw-bold number">
                  </p>
                  </div>
                  <div class="align-self-center rounded-circle p-2 shadow bg-green">
                    <i class="fas fa-users fa-lg  secondary-text-dark"></i>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-bg shadow rounded-3 border-0 mb-2">
          <div class="card-body">
              <div class="d-flex justify-content-between px-md-1">
                  <div>
                    <h6>Assigned Questions</h6>
                  <p class="mb-0 sub_heading text-xsm fw-bold number">  
                  </p>
                  </div>
                  <div class="align-self-center rounded-circle p-2 shadow bg-green">
                    <i class="fas fa-user-shield fa-lg secondary-text-dark"></i>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>

    <div v-if="loading" class="text-center mt-5">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div v-else>
    <div class="row mt-0">
      <div class="col-md-12">
        <div class="card rounded-3 p-0 mt-4 shadow">
          <div class="card-header bg-white border-0">
           Service Form
            <button type="button" class="btn btn-dark rounded-small fw-bold text-xxs text-white float-end shadow" data-bs-toggle="modal" data-bs-target="#add_service">
              <i class="fas fa-plus"></i> Add Custom Service
            </button>
          </div>
          <div class="card-body">
            <table id="form_table" class="table-responsive w-100">
              <thead class="bg-dark text-white">
                <tr>
                  <th class="bg-dark text-center text-uppercase text-xxs font-weight-bolder opacity-7">No.</th>
                  <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7">Custom Service</th>
                  <th class="bg-dark text-text-uppercase text-xxs font-weight-bolder opacity-7">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(service, index) in services" :key="service.id">
                  <td class="text-center text-xxs">{{index + 1}}</td>
                  <td class="text-xxs">{{service.service_name}}</td>
                  <td>
                    <button class="btn bg-green rounded-small btn-sm me-2 text-xxs shadow" @click.prevent="manage_service(service.id)">Manage Service</button>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Add Service Modal -->
  <div class="modal fade" id="add_service" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="add_service_label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-white">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title text-s fw-bold" id="add_service_label">Add Custom Service</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group mb-3">
            <input type="text" v-model="service_name" class="form-control text-xxs text-dark p-2" placeholder="Service Name">
            <small class="text-danger p-2 text-xxs fw-bold d-none workflow-name-messages">Please enter a service name</small>
            <small class="text-danger p-2 text-xxs fw-bold d-none error-messages"></small>
          </div>
          <div class="mb-3">
            <select v-model="service_type" class="form-select text-xs" name="service_type" required>
              <option value="" disabled hidden>Service Type</option>
              <option v-for="type in service_types" :key="type.id" :value="type.id">{{ type.type }}</option>
            </select>
            <div class="type-message d-none">Service type is required</div>
          </div>
          <div class="text-end">
            <button
            type="button"
            class="btn bg-green rounded-pill text-xs fw-bold"
            @click.prevent="create_custom_service">Create</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>