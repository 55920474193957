import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";

export default {
    name: 'QualCheck',
    mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
    components:{
        BreadCrumb,
        ClientLogo
    },
    data: () => ({
        first_names: '',
        last_name: '',
        id_number: '',
        qualification: '',
        institution_name: '',
        json_data: {},
        years: Array.from({ length: 75 }, (_, i) => new Date().getFullYear() - i),
        selected_year: '',
    }),

    computed: {
        workflow_id() {
            return this.$store.getters.get_workflow_id;
        }
    },

    methods: {
        qualification_check_api() {
            const TOKEN = localStorage.getItem('token');
            const QUALIFICATION_CHECK_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/qualification_check_api/`;

            this.error = '';

            $.ajax({
                url: QUALIFICATION_CHECK_API,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    first_names: this.first_names,
                    surname: this.last_name,
                    id_number: this.id_number,
                    qualification: this.qualification,
                    institution_name: this.institution_name,
                    qualification_year: this.selected_year,
                    workflow_id: this.workflow_id
                }),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => Swal.showLoading(),
                    });
                },
                success: (response) => {
                    Swal.close();
                    this.fetch_current_service();
                    var status_message = response.status;

                    if (status_message == "success") {
                        this.fetch_current_service();
                    } else if (status_message == "Results not found please contact your service provider") {
                        Swal.fire({
                            icon: 'error',
                            text: "Our service is currently unavailable. Please try again later",
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: "Our service is currently unavailable. Please try again later",
                        })
                    }
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Something went wrong!';

                    if (error.responseJSON && error.responseJSON.message) {
                        error_message = error.responseJSON.message;
                        $('.error-message').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.error-message').text('').addClass('d-none');
                        }, 3000);
                    } else if (error.responseJSON.status && error.responseJSON.status == "error") {
                        Swal.fire({
                            icon: 'error',
                            text: "Our service is currently unavailable. Please try again later",
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: "Our service is currently unavailable. Please try again later",
                        })
                    }
                }
            });
        },
    },

    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
    }
};