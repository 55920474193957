<div>
    <p class="fw-bold mb-4 text-x">Registration</p>
    <form @submit.prevent="handle_register">
        <div class="form-group mb-2">
            <input
            type="text"
            class="form-control rounded text-xs"
            v-model="first_name"
            placeholder="First Name"
            name="first_name"
            @input="validate_name('first_name')"
            />
            <small class="text-danger p-2 text-xxs fw-bold d-none first-name-message">Please fill your first name</small>
        </div>

        <div class="form-group mb-2">
            <input
            type="text"
            class="form-control rounded text-xs"
            v-model="last_name"
            placeholder="Last Name"
            name="last_name"
            @input="validate_name('last_name')"
            />
            <small class="text-danger p-2 text-xxs fw-bold d-none last-name-message">Please fill your last name</small>
        </div>

        <div class="form-group mb-2">
            <input
            type="email"
            class="form-control rounded text-xs"
            v-model="email"
            placeholder="Email"
            name="email"
            />
            <small class="text-danger p-2 text-xxs fw-bold d-none email-message">Please fill your email address</small>
            <small class="text-danger p-2 text-xxs fw-bold d-none email-exists-message"></small>
            <small class="text-danger p-2 text-xxs fw-bold d-none email-error-message"></small>
        </div>

        <div class="form-group mb-2">
            <input
            type="email"
            class="form-control rounded text-xs"
            v-model="confirm_email"
            placeholder="Confirm Email"
            name="confirm_email"
            @input="email_match"
            />
            <small class="text-danger p-2 text-xxs fw-bold d-none confirm-email-message">Please confirm your email address</small>
            <small class="text-danger p-2 text-xxs fw-bold d-none email-match-message">Emails do not match</small>
        </div>

        <div class="form-group mb-2">
            <input
            type="text"
            class="form-control rounded text-xs"
            v-model="phone_number"
            placeholder="Contact Number"
            name="phone_number"
            title="Please enter a 10-digit phone number"
            maxlength="10"
            @input="validate_phone_number"
            />
            <small class="text-danger p-2 text-xxs fw-bold d-none phone-number-message">Please provide your cellphone number</small>
            <small class="text-danger p-2 text-xxs fw-bold phone-max-message" v-if="phone_number_error">phone number must contain 10 numbers</small>
            <small class="text-danger p-2 text-xxs fw-bold d-none mobile-exists-message"></small>
        </div>

        <div class="form-group mb-2">
            <input
            type="password"
            class="form-control rounded text-xs"
            v-model="password"
            placeholder="Password"
            name="password"
            />
            <small class="text-danger p-2 text-xxs fw-bold d-none password-message">Please create a password</small>
        </div>

        <div class="form-group mb-2">
            <input
            type="password"
            class="form-control rounded text-xs"
            v-model="confirm_password"
            placeholder="Confirm Password"
            name="confirm_password"
            @input="password_match"
            />
            <small class="text-danger p-2 text-xxs fw-bold d-none confirm-password-message">Please confirm your password</small>
            <small class="text-danger p-2 text-xxs fw-bold d-none match-password-message">Passwords do not match</small>
        </div>

        <div>
          <GetQuestions :client_admin_id="client_admin_id" ref="get_questions" page_type="register" @update-responses="store_responses" />
        </div>
      

        <div class="text-center">
            <small class="text-danger p-2 text-xxs fw-bold d-none error-message "></small>
            <button type="submit" class="btn bg-green w-75 rounded text-xs fw-bold fs-6 mb-2 mt-3 shadow">
            Continue
            </button>
        </div>
    </form>

    <div class="text-center mb-2">
        <span class="text-dark text-xs">Already have an account?</span>
        <a class="text-primary text-xs"><router-link to="/"> Login here</router-link></a>
    </div>
</div>