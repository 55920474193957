<div class="container-fluid bg-white vh-100 p-0">
  <ClientLogo />
	<BreadCrumb label="Anti Money Laundering" />

  <div>
		<div class="d-flex justify-content-center align-items-center min-vh-50">
			<div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
				<div class="form-group mt-3">
          <label for="firstName" class="fw-bold text-xs text-start d-block">First Name <span class="text-danger">*</span></label>
          <input
            v-model="first_name"
            type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            id="firstName"
            placeholder="e.g., Joe"
            @input="validate_first_name"
            required
          />
          <small v-if="error_messages.first_name" class="form-text text-danger">
            {{ error_messages.first_name }}
          </small>

          <label for="lastName" class="fw-bold text-xs text-start d-block mt-3">Last Name <span class="text-danger">*</span></label>
          <input
            v-model="last_name"
            type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            id="lastName"
            placeholder="e.g., Doe"
            @input=" validate_last_name"
            required
          />
          <small v-if="error_messages.last_name" class="form-text text-danger">
            {{ error_messages.last_name }}
          </small>

          <label for="dateOfBirth" class="fw-bold text-xs text-start d-block mt-3">Date of Birth <span class="text-danger">*</span></label>
          <input
            v-model="date_of_birth"
            type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            id="dateOfBirth"
            placeholder="YYYYMMDD"
            @input="date_of_birth_validation"
            required
          />
          <small class="form-text text-danger d-none text-xxs fw-bold invalid-dob">
            Invalid Date of Birth. Please enter a valid date in YYYYMMDD format.
          </small>

          <label for="gender" class="fw-bold text-xs text-start d-block mt-3">Gender <span class="text-danger">*</span></label>
          <select 
            v-model="gender" 
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm" 
            id="gender" 
            required
          >
            <option value="" disabled hidden>Choose Gender</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>

          <label for="countryCode" class="fw-bold text-xs text-start d-block mt-3">Country Code <span class="text-danger">*</span></label>
          <input
            v-model="country_code"
            type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            id="countryCode"
            placeholder="e.g., ZAF"
            @input="countries_code"
            required
          />
          <small v-if="error_messages.country_code" class="form-text text-danger">
            {{ error_messages.country_code }}
          </small>
        </div>
      </div>
    </div>

    <div class="text-center mb-3 mt-4">
      <button
        class="btn bg-green btn-sm px-4 rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
        @click.prevent="anti_money_laundering_application_api"
      >
        Continue
      </button>
    </div>
  </div>
</div>




  