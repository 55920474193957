<div class="container py-5 mb-1">
  <div class="row">
    <div v-if="qr_code_data && qr_code_data.link && qr_code_data.qr_code" class="col-lg-10 ms-0 mb-4">
      <div class="card h-80 rounded-small border-0 shadow-sm p-2">
        <div class="card-body row g-0 align-items-start">
          <div class="col-auto">
            <img class="mb-3" :src="qr_code_data.qr_code" width="180px" />
          </div>
          <div class="col ms-3">
            <div>
              <label><strong>Asset Name : </strong></label>
              <p class="mb-2">{{ asset_data.asset_name }}</p>
            </div>
            <div>
              <label><strong>Link : </strong></label>
              <a :href="qr_code_data.link" target="_blank" rel="noopener noreferrer">
                {{ qr_code_data.link }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-10 ms-0">
      <div class="card h-100 rounded-small shadow">
        <div class="card-body p-3 mb-5">
          <p class="text-uppercase text-xs text-blue fw-bold mt-4">Asset Information</p>
          <div class="underline rounded-small mt-1"></div>

          <form @submit.prevent="edit_asset">
            <div class="mb-3">
              <label class="form-label fw-bold text-xs">Name</label>
              <input 
                type="text" 
                class="form-control form-control-lg" 
                placeholder="Enter asset name"
                v-model="asset_details.asset_name"
              >
            </div>

            <div class="mb-3">
              <label class="form-label fw-bold text-xs">Description</label>
              <input 
                type="text" 
                class="form-control form-control-lg" 
                placeholder="Enter description"
                v-model="asset_details.asset_description"
              >
            </div>

            <div class="mb-3">
              <label class="form-label fw-bold text-xs">Asset Type</label>
              <input disabled
                type="text" 
                class="form-control form-control-lg" 
                placeholder="Enter asset type"
                v-model="asset_details.asset_type__asset_type"
              >
            </div>

            <div class="col mb-2">
              <label class="form-label fw-bold text-xs"><small>Upload an image</small></label>
              <input 
                type="file" 
                accept="image/*" 
                @change="on_file_change" 
                class="form-control text-xs"
              >
            </div>

            <div class="modal-footer text-end">
              <button 
                type="submit" 
                class="btn bg-green text-xs fw-bold rounded-small shadow"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
