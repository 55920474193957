<div class="container-fluid bg-white vh-100 p-0">
    <ClientLogo />
    <BreadCrumb label="Qualification Check" />

    <div class="d-flex flex-column align-items-center  min-vh-50">
        <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
            <div class="form-group mt-3">
                <label for="first_name" class="fw-bold text-xs text-start d-block">
                    First Names <span class="text-danger">*</span>
                </label>
                <input
                    v-model="first_names"
                    type="text"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    id="first_name"
                    placeholder="e.g. Joe"
                    required
                />

                <label for="last_name" class="fw-bold text-xs text-start d-block mt-3">
                    Last Name <span class="text-danger">*</span>
                </label>
                <input
                    v-model="last_name"
                    type="text"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    id="last_name"
                    placeholder="e.g. Doe"
                    required
                />

                <label for="id_number" class="fw-bold text-xs text-start d-block mt-3">
                    ID Number <span class="text-danger">*</span>
                </label>
                <input
                    v-model="id_number"
                    type="text"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    id="id_number"
                    placeholder="e.g. 9812160854083"
                    required
                />

                <label for="qualification" class="fw-bold text-xs text-start d-block mt-3">
                    Qualification <span class="text-danger">*</span>
                </label>
                <input
                    v-model="qualification"
                    type="text"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    id="qualification"
                    placeholder="e.g. Bachelor of Architecture"
                    required
                />

                <label class="form-label fw-bold text-xs text-start d-block mt-3">
                    Qualification Year <span class="text-danger">*</span>
                </label>
                <select
                    v-model="selected_year"
                    class="form-select mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    required
                >
                    <option value="" disabled hidden>Select Year</option>
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select>

                <label for="institution_name" class="fw-bold text-xs text-start block mt-3">
                    Institution Name <span class="text-danger">*</span>
                </label>
                <input
                    v-model="institution_name"
                    type="text"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    id="institution_name"
                    placeholder="e.g. Rhodes University"
                    required
                />
            </div>
        </div>

        <div class="text-center mb-3 mt-4">
            <button
                class="btn btn-sm bg-green secondary-text-dark rounded-small fw-bold text-xs shadow-sm"
                @click.prevent="qualification_check_api"
            >
                Continue
            </button>
        </div>
    </div>
</div>
