<div class="container-fluid bg-white vh-100 p-0">
    <ClientLogo />
	<BreadCrumb label="RSA ID Liveness Verification" />

    <div class="d-flex justify-content-center align-items-center min-vh-50">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-1">
            <div class="text-center">
                <small class="text-danger mb-2 fw-bold text-xs">
                    <i class="fa fa-info-circle me-1"></i>
                    Please make sure you have your camera enabled!
                </small>
            </div>

            <!-- ID Number -->
            <div class="form-group justify-content-center">
                <label for="id_number" class="form-label fw-bold text-xxs">ID Number <span
                        class="text-danger fw-bold">*</span></label>
                <input id="id_number" type="text"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm justify-content-center"
                    v-model="id_number" placeholder="Enter an SA ID Number" @input="handle_input" maxlength="13">
                <div class="d-flex flex-column col-12 col-md-9 col-lg-8 mx-auto mt-1">
                    <small class="text-danger fw-bold text-xs d-none id-message">
                        Please fill out this field
                    </small>
                    <small class="text-danger fw-bold text-xs d-none invalid-message">
                        Please enter a valid ID number
                    </small>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12 col-md-6 mb-3 text-center" id="take_photo">
                    <figure
                        class="content position-relative d-flex justify-content-center align-items-center custom-height">
                        <video ref="video" id="video" class="invert mb-2 position-relative w-100 h-100" autoplay loop
                            muted playsinline></video>
                        <div class="circle-overlay"></div>
                    </figure>
                    <button @click="take_photo"
                        class="btn bg-green shadow-sm rounded-small text-xs w-75 fw-bold shadow mt-2 mt-md-0">
                        {{ photo_taken ? 'Retake' : 'Take Photo' }}
                    </button>
                </div>

                <div class="col-12 col-md-6 mb-3 text-center" id="submit_photo">
                    <figure
                        class="content position-relative d-flex justify-content-center align-items-center custom-height">
                        <input v-model="photo_src" name="src" type="text" class="d-none" />
                        <canvas ref="canvas" id="canvas" class="mb-2 position-relative w-100 h-100"></canvas>
                    </figure>
                    <div class="d-flex flex-column flex-md-row justify-content-center align-items-center">
                        <button @click="retake_photo" type="button"
                            class="btn bg-green rounded-small text-xs w-75 fw-bold shadow d-none retake-photo justify-content-center">
                            Retake Photo
                        </button>
                        <button @click="submit_photo" type="submit"
                            class="btn bg-green shadow-sm rounded-small text-xs w-75 fw-bold shadow mt-2 mt-md-0"
                            :disabled="!photo_taken">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Create Instructions Modal -->
<div class="modal fade" id="show_instruction" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-4">
            <div class="text-center mt-3 mb-4">
                <img :src="$clientLogo" width="150px" />
            </div>
            <p class="text-center text-xs">We need to verify your identity to secure your basic information.</p>
            <p class="text-center text-xs fw-bold mb-1">Please read the below instructions carefully:</p>
            <div class="text-center">
                <hr class="bg-secondary reduced-opacity">
            </div>
            <div class="modal-body px-4">
                <div class="d-flex align-items-center text-xs mb-3">
                    <i class="fa fa-camera me-3 text-success"></i>
                    <span>Ensure your camera lens is clean</span>
                </div>
                <div class="d-flex align-items-center text-xs mb-3">
                    <i class="fa fa-lightbulb me-3 text-success"></i>
                    <span>You need good lighting and avoid bright lights behind you</span>
                </div>
                <div class="d-flex align-items-center text-xs mb-3">
                    <i class="fa-regular fa-circle-check me-3 text-success"></i>
                    <span>Choose a clear background, like a wall</span>
                </div>
                <div class="d-flex align-items-center text-xs mb-3">
                    <i class="fa fa-glasses me-3 text-success"></i>
                    <span>Remove any hats, sunglasses, or any form of eyewear</span>
                </div>
                <div class="d-flex align-items-center text-xs mb-3">
                    <i class="fa fa-user-circle me-3 text-success"></i>
                    <span>Move your face closer to the camera, with your neck excluded in the circumference</span>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn bg-green fw-bold rounded-small text-xxs px-4" data-bs-dismiss="modal">
                    Take Photo
                </button>
            </div>
        </div>
    </div>
</div>
