<div class="container-fluid bg-white vh-100 p-0 d-flex flex-column align-items-center">
    <div class="text-center mt-5 mb-2">
        <img :src="$clientLogo" width="200px" />
    </div>

    <div class="text-center mt-1 w-100">
        <nav aria-label="breadcrumb" class="w-50 mx-auto">
            <ol class="breadcrumb rounded-3 justify-content-center bg-dark text-white p-2">
                <li class="breadcrumb-item active cursive-text text-white" aria-current="page">
                    Director Information
                </li>
            </ol>
        </nav>
    </div>

    <div class="d-flex flex-column align-items-center w-50 mt-2">
        <div v-for="(director, index) in masked_directors" :key="index" class="w-100 mb-4">
            <h5 class="fw-bold text-xs text-start w-75 mb-2">Director {{ index + 1 }}</h5>

            <div class="form-group mb-4 w-75">
                <label for="full_name" class="fw-bold text-xs text-start d-block">Full Name</label>
                <input type="text"
                    class="form-control rounded-small text-xs p-3 shadow-sm w-100" 
                    :id="'full_name_' + index"
                    v-model="director.full_name" 
                    disabled />
            </div>

            <div class="form-group mb-4 w-75">
                <label for="id_number" class="fw-bold text-xs text-start d-block"> ID Number</label>
                <input 
                    type="text"
                    class="form-control rounded-small text-xs p-3 shadow-sm w-100" 
                    :id="'id_number_' + index"
                    :value="director.masked_id_number"
                    disabled />
            </div>

            <div class="form-group mb-4 w-75">
                <label for="email" class="fw-bold text-xs text-start d-block">Email <span class="text-danger">*</span></label>
                <input 
                    type="email" 
                    class="form-control rounded-small text-xs p-3 shadow-sm w-100" 
                    v-model="email[index]"
                    required />
                <small class="form-text text-danger d-none text-xxs fw-bold invalid-dob">
                    Email is invalid
                </small>
            </div>

            <div class="form-group mb-4 w-75">
                <label for="phone_number" class="fw-bold text-xs text-start d-block">Phone number <span
                        class="text-danger">*</span></label>
                <input 
                    type="text"
                    class="form-control rounded-small text-xs p-3 shadow-sm w-100"
                    v-model="phone_number[index]"  
                    required />
                <small class="form-text text-danger d-none text-xxs fw-bold invalid-dob">
                    Phone number is invalid
                </small>
            </div>
        </div>

        <div class="text-center mb-3 mt-2">
            <button class="btn btn-sm bg-green text-dark rounded-small fw-bold text-xs shadow-sm"
                @click.prevent="update_director_information">
                Continue
            </button>
        </div>
    </div>
</div>