<div class="onboarding">
<section class="index-section">
    <div class="container">
        <div class="row justify-content-center">
            <div v-if="change_col" class="col-md-8 p-3">
                <div class="card shadow p-4 p-md-3">
                    <div class="text-center">
                    <img :src="$clientLogo" class="mb-4" width="200px">
                    <router-view></router-view>
                    </div>
                </div>
            </div>
            <div v-else class="col-md-6 col-lg-5">
                <div class="card shadow p-4 p-md-3">
                    <div class="text-center">
                    <img :src="$clientLogo" class="mb-4" width="200px">
                    <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
