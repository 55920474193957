<div class="client-dashboard" :class="{ 'sidebar-hidden': hide_sidebar }">
  <div :class="wrapper">

    <div v-if="!hide_sidebar">
       <Sidebar />
       
    </div>

    <div class="main-content">
      <NavBar @toggle-sidebar="toggle_sidebar" />

      <router-view></router-view>
    </div>
  </div>
</div>