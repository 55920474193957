<div class="client-sidebar shadow">
    <div class="sidebar-brand text-center mt-3">
        <img src="@/assets/images/white_and_green.png" alt="Logo" class="sidebar-logo img-fluid" />
      </div>
    
    <hr class="fw-bold mb-4 mt-1 mx-4 text-white">
    <ul class="nav flex-column mb-auto">
       <span class="text-xxs fw-bold p-0">Dashboard</span>
        <li class="nav-item mt-0 mb-0 p-2">
            <router-link to="/system_admin/admin_dashboard"
             class="nav-link" active-class="active">
                <i class="fas fa-tachometer-alt me-2 green-color"></i>
                Dashboard
            </router-link>
        </li>
        <span class="text-xxs fw-bold p-0 mt-1">Management</span>
        <li class="nav-item mt-0 mb-0 p-2">
            <router-link to="/system_admin/client_applications"
             class="nav-link " active-class="active">
                <i class="fas fa-file-alt me-2 green-color"></i>
                Applications
            </router-link>
        </li>
        <li class="nav-item mt-0 mb-0 p-2">
            <router-link to="/system_admin/users" class="nav-link"
            active-class="active">
                <i class="fas fa-users me-2 green-color"></i>
                Users
            </router-link>
        </li>
        <li class="nav-item mt-0 mb-0 p-2">
            <router-link to="/system_admin/service_applications" class="nav-link text-white"
            active-class="active">
                <i class="fas fa-users me-2 green-color"></i>
                Instant Verify 
                <div class="p-1">Requests</div>
            </router-link>
        </li>
    </ul>
    <div class="logout">
        <li  class="text-decoration-none">
            <a href="#" class="text-xs  text-white" @click="route_to"><i class="fas fa-sign-out-alt me-2 green-color"></i>Logout</a>
        </li>
    </div>
</div>
