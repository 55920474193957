export default {
  name: 'GetQuestions',
  props: {
    client_admin_id: {
      type: String,
      required: true,
    },
    field: Object,
    page_type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      file_input: null,
      uploaded_file: null,
      dynamic_fields: [],
      showLabel: true,
    };
  },
  mounted() {
    this.get_questions();
    this.file_input = this.$refs.file_input;
  },
  methods: {
    get_questions() {
      const BASE_URL = process.env.API_BASE_URL;
      let end_point = '';

      switch (this.page_type) {
        case 'register':
          end_point = 'get_register_form_api';
          break;
        case 'otp':
          end_point = 'get_otp_form_api';
          break;
        case 'customer_id_verification':
          end_point = 'get_id_verification_api';
          break;
        case 'login':
          end_point = 'get_login_form_api';
          break;
        default:
          end_point = 'get_register_form_api';
          return;
      }

      const TOKEN = localStorage.getItem('token');
      const GET_CUSTOMER_REGISTRATION_FORM_URL = `${BASE_URL}/verify_api/system_management_api/${end_point}/?client_admin_id=${this.client_admin_id}`;

      const headers = this.page_type !== 'register' ? { Authorization: `Token ${TOKEN}` } : {};
      $.ajax({
        method: "GET",
        url: GET_CUSTOMER_REGISTRATION_FORM_URL,
        contentType: 'application/json',
        headers: headers,
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();
          if (response.form_questions && Array.isArray(response.form_questions)) {
            this.dynamic_fields = response.form_questions.map((question) => ({
              service_id: question['service_id'],
              question_id: question['question_id'],
              question: question['question__question'],
              is_required: question['question__is_required'],
              question_number: question['question__number'],
              has_checkbox: question['question__has_checkbox'],
              has_date: question['question__has_date'],
              has_file: question['question__has_file'],
              has_number: question['question__has_number'],
              has_selection: question['question__has_selection'],
              has_text: question['question__has_text'],
              options: question['question__has_checkbox']
                ? question['question__checkbox__option']
                : question['question__has_selection']
                ? question['question__selectionoption__option']
                : [],
              value: question['question__has_checkbox'] ? [] : '',
              error_message: '',
            }));
            this.emit_responses();
          }
        },
        error: () => {
          Swal.close();
        },
      });
    },
    handle_file_upload(event, index) {
      const file = event.target.files[0];
      this.dynamic_fields[index].value = file;
      this.emit_responses();
    },
    on_file_change(event, index) {
      const FILE = event.target.files[0];
      const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
    
      // Check for file size
      if (FILE.size > MAX_SIZE) {
        event.target.value = null;
        Swal.fire({
          text: 'File is too large.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }
    
      // Handle the file upload if the file size is acceptable
      this.handle_file_upload(event, index);
    },
    validate_fields() {
      let is_valid = true;
      this.dynamic_fields.forEach((field) => {
        if (field.is_required && !field.value) {
          field.error_message = `${field.question} is required.`;
          is_valid = false;
        } else {
          field.error_message = '';
        }
      });
      this.emit_responses();
      return is_valid;
    },
    emit_responses() {
      this.$emit('update-responses', this.dynamic_fields);
    },
  },
  watch: {
    dynamic_fields: {
      handler() {
        this.emit_responses();
      },
      deep: true,
    },
  },
};


  