<div class="container-fluid">
    <div class="card rounded-3 mt-4 p-0">
        <div class="card-header bg-dark text-center p-2 rounded-top">
            <h6 class="card-heading fw-bold text-center text-dark text-s text-start">Logs</h6>
        </div>

        <div class="tab-content ms-2 me-2">
            <div class="tab-pane fade show active">
            <table id="logs_table" class="table table-clear">
                <thead class="bg-gray text-dark">
                <tr>
                    <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">ID</th>
                    <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">service name</th>
                    <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">Verification status</th>
                    <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">API status result</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(log, index) in logs" :key="log.id">
                    <td class="text-xs fw-bold text-start">{{ index + 1 }}</td>
                    <td class="text-xs fw-bold">{{ log.service_name }}</td>
                    <td class="text-xs fw-bold">{{ log.verification_status }}</td>
                    <td class="text-xs fw-bold">{{ log.api_result_status }}</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
  </div>