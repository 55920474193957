<div class="container-fluid bg-white vh-100 p-0">
	<ClientLogo />
	<BreadCrumb label="Address Match" />
	<div>
		<IdNumberInput @update-id-number="handle_id_input"/>
		<div class="text-center">
			<button 
			  type="submit" 
			  class="btn bg-green btn-sm mt-1 rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
			  @click="submit_id"
			>
			  Continue
			</button>   
		</div>
	</div>
</div>