<header>
    <nav class="navbar-landing">
      <img src="@/assets/images/mobi_logo.png" alt="Logo" class="logo sidebar-logo img-fluid" />
    </nav>
  </header>
  
  <section class="hero-section-landing">
    <img src="@/assets/images/green_africa.png" alt="Banner" class="hero-image" />
    <div class="hero-content">
      <h1 class="hero-title text-dark">A New Journey Begins.</h1>
      <button class="mobi-hero-button fw-bold text-dark" @click="next_to_register">Get Started</button>
    </div>
  </section>