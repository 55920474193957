
<div class="text-center">
    <p class="text-dark fw-bolder mb-3 text-x">Company Information</p>
</div>
<div class="form-group mb-3">
    <input 
    type="text"
    class="form-control rounded text-xs"
    v-model="company_name" 
    name="company_name"
    placeholder="Enter Company Name" 
    >
    <small class="text-danger p-2 text-xxs fw-bold d-none company-message">Please fill out this field</small>
</div>

<div class="form-group mb-3">
    <input 
    type="text"
    placeholder="Company Registration" 
    class="form-control rounded text-xs" 
    v-model="reg_number"
    name="reg_number"
    @input="validate_reg_number" 
    >
    <small class="text-danger p-2 text-xxs fw-bold d-none reg-message">Please fill out this field</small>

</div>

<div class="text-danger p-2 text-xxs fw-bold error-messages"></div>

<div class="text-center mb-3 mt-3">
    <button 
    type="submit" 
    class="btn btn-sm bg-green shadow-lg rounded fw-bold w-75"
    @click="save_company_info"
    >
    Continue</button>   
</div>