import { createRouter, createWebHistory } from 'vue-router';
import OnboardingIndex from '@/layouts/public_layout/OnboardingIndex.vue';
import UserLogin from '@/layouts/public_layout/onboarding/login/UserLogin.vue';
import UserOtp from '@/layouts/public_layout/onboarding/otp/UserOtp.vue';
import UserRegistration from '@/layouts/public_layout/onboarding/registration/UserRegistration.vue';
import IdVerification from '@/layouts/public_layout/onboarding/id_verification/IdVerification.vue';
import FacialBiometrics from '@/layouts/public_layout/onboarding/facial_biometrics/FacialBiometrics.vue';
import CompanyVerification from '@/layouts/public_layout/onboarding/company_verification/CompanyVerification.vue';
import CompanyPending from '@/layouts/public_layout/onboarding/company_pending/CompanyPending.vue';
import ClientDashboard from '@/layouts/client/client_dashboard/ClientDashboard.vue';
import EditWorkflow from '@/layouts/client/edit_workflow/EditWorkflow.vue';
import ViewWorkflow from '@/layouts/client/view_workflow/ViewWorkflow.vue';
import ClientServices from '@/layouts/client/client_services/ClientServices.vue';
import ArrangeWorkflow from '@/layouts/client/arrange_workflow/ArrangeWorkflow.vue';
import ReasonsForUsage from '@/layouts/client/reasons_for_usage/ReasonsForUsage.vue';
import UploadConsent from '@/layouts/client/upload_consent/UploadConsent.vue';
import ConfirmWorkflow from '@/layouts/client/confirm_workflow/ConfirmWorkflow.vue';
import WorkflowDashboard from '@/layouts/client/workflow_dashboard/WorkflowDashboard.vue';
import CustomerIndex from '@/layouts/customer/CustomerIndex.vue';
import FacialBiometricsVerification
from '@/layouts/credit_bureau_services/facial_biometrics_verification/FacialBiometricsVerification.vue';
import CreditReport from '@/layouts/credit_bureau_services/credit_report/CreditReport.vue';
import IncomeEstimator from '@/layouts/credit_bureau_services/income_estimator/IncomeEstimator.vue';
import IncomePredictor from '@/layouts/credit_bureau_services/income_predictor/IncomePredictor.vue';
import AddressMatch from '@/layouts/credit_bureau_services/address_match/AddressMatch.vue';
import CustomerDashboard from '@/layouts/customer/customer_dashboard/CustomerDashboard.vue';
import AssignedWorkflows from '@/layouts/customer/workflows/AssignedWorkflows.vue';
import CustomerConsent from '@/layouts/customer/customer_consent/CustomerConsent.vue';
import ThankYouPage from '@/layouts/public_layout/thank_you_page/ThankYouPage.vue';
import ViewCustomers from '@/layouts/client/view_customers/ViewCustomers.vue';
import ProjectTable from '@/layouts/client/project_table/ProjectTable.vue';
import ProjectDashboard from '@/layouts/client/project_dashboard/ProjectDashboard.vue';
import ServiceDashboard from '@/layouts/client/service_dashboard/ServiceDashboard.vue';
import WorkflowServices from '@/layouts/client/workflow_services/WorkflowServices.vue';
import ViewService from '@/layouts/client/view_service/ViewService.vue';
import AdminComponents from '@/layouts/admin/admin_components/AdminComponents.vue';
import AdminDashboard from '@/layouts/admin/admin_dashboard/AdminDashboard.vue';
import ClientApplications from '@/layouts/admin/client_applications/ClientApplications.vue';
import SystemUsers from '@/layouts/admin/system_users/SystemUsers.vue';
import ViewAdmin from '@/layouts/admin/view_admin/ViewAdmin.vue';
import ViewClient from '@/layouts/admin/view_client/ViewClient.vue';
import ViewPendingApplication from '@/layouts/admin/view_pending_application/ViewPendingApplication.vue';
import ViewApprovedApplication from '@/layouts/admin/view_approved_application/ViewApprovedApplication.vue';
import ViewDeclinedApplication from '@/layouts/admin/view_declined_application/ViewDeclinedApplication.vue';
import ViewDeactivatedApplication from '@/layouts/admin/view_deactivated_application/ViewDeactivatedApplication.vue';
import ViewCustomer from '@/layouts/admin/view_customer/ViewCustomer.vue';
import ViewClientAdmin from '@/layouts/admin/view_client_admin/ViewClientAdmin.vue';
import QuestionManagement from '@/layouts/client/question_management/QuestionManagement.vue';
import FormManagement from '@/layouts/client/form_management/FormManagement.vue';
import ManageForm from '@/layouts/client/manage_form/ManageForm.vue';
import TemplateManagement from '@/layouts/client/template_management/TemplateManagement.vue';
import TemplateConfiguration from '@/layouts/client/template_configuration/TemplateConfiguration.vue';
import InstantVerify from '@/layouts/client/instant_verify/InstantVerify.vue';
import ServiceApplications from '@/layouts/admin/service_applications/ServiceApplications.vue';
import InstantServices from '@/layouts/client/view_instant_services/InstantServices.vue';
import YourServices from '@/layouts/client/services/YourServices.vue';
import CustomerProfile from '@/layouts/customer/customer_profile/CustomerProfile.vue';
import AntiMoneyLaundering from '@/layouts/services/anti_money_laundering/AntiMoneyLaundering.vue';
import ServiceIndex from '@/layouts/services/ServiceIndex.vue';
import RefugeeVerification from '@/layouts/services/refugee_verification/RefugeeVerification.vue';
import VerifyLogs from '@/layouts/client/verify_logs/VerifyLogs.vue';
import ViewLogs from '@/layouts/admin/view_logs/ViewLogs.vue';
import ClientRequestedServices from '@/layouts/admin/client_requested_services/ClientRequestedServices.vue';
import ClientAdmin from '@/layouts/client/client_admin/ClientAdminUsers.vue';
import CIPCVerification from '@/layouts/credit_bureau_services/cipc_verification/CIPCVerification.vue';
import CustomService from '@/layouts/credit_bureau_services/custom_service/CustomService.vue';
import AMLVerification from '@/layouts/credit_bureau_services/aml_verification/AMLVerification.vue';
import CustomerData from '@/layouts/client/customer_data/CustomerData.vue';
import CreateAsset from '@/layouts/client/create_asset/CreateAsset.vue';
import QuestionBasedAuthentication
from '@/layouts/credit_bureau_services/question_based_authentication/QuestionBasedAuthentication.vue';
import ViewAsset from '@/layouts/client/view_asset/ViewAsset.vue';
import CompanyVerificationPdf
from '@/layouts/services/company_verification_pdf/CompanyVerificationPdf.vue';
import RsaIdLivenessVerification 
from '@/layouts/credit_bureau_services/rsa_id_liveness_verification/RsaIdLivenessVerification.vue';
import RefugeeLivenessVerification
from '@/layouts/credit_bureau_services/refugee_liveness_verification/RefugeeLivenessVerification.vue';
import SearchFcra from '@/layouts/credit_bureau_services/search_fcra/SearchFcra.vue';
import ProofOfIncomeManual
from '@/layouts/credit_bureau_services/proof_of_income_manual/ProofOfIncomeManual.vue';
import ProofOfIncomeOnline
from '@/layouts/credit_bureau_services/proof_of_income_online/ProofOfIncomeOnline.vue';
import EligibilityService
from '@/layouts/credit_bureau_services/eligibility_service/EligibilityService.vue';
import DirectorInfo from '@/layouts/customer/director_info/DirectorInfo.vue';
import UploadProofOfAddress from '@/layouts/public_layout/onboarding/upload_proof_of_address/UploadProofOfAddress.vue';
import DirectorConsent from '@/layouts/public_layout/onboarding/director_consent/DirectorConsent.vue';
import BankAccountVerification from '@/layouts/credit_bureau_services/bank_account_verification/BankAccountVerification.vue';
import QualificationCheck from '@/layouts/services/qualification_check/QualificationCheck.vue';
import AfisCriminalCheck from '@/layouts/services/afis_criminal_check/AfisCriminalCheck.vue';
import WizzitLandingPage from '@/layouts/public_layout/wizzit_landing_page/WizzitLandingPage.vue';
import VerifyLandingPage from '@/layouts/public_layout/verify_landing_page/VerifyLandingPage.vue';
import MobiLandingPage from '@/layouts/public_layout/mobi_landing_page/MobiLandingPage.vue'
import QualCheck from '@/layouts/credit_bureau_services/qual_check/QualCheck.vue';
import AfisCrimCheck from '@/layouts/credit_bureau_services/afis_crim_check/AfisCrimCheck.vue';

const domain = window.location.hostname
let landingPageComponent

if (domain.includes('verify')) {
  landingPageComponent = VerifyLandingPage;
} else if (domain.includes('wizzit')) {
  landingPageComponent = WizzitLandingPage;
}else if (domain.includes('mobiafrica')) {
  landingPageComponent = MobiLandingPage;
}

const routes = [
  {
    path: '/',
    name: 'OnboardingIndex',
    component: OnboardingIndex,
    children: [
      {
        path: '',
        name: 'user_login',
        component: UserLogin
      },
      {
        path: 'user_otp',
        name: 'user_otp',
        component: UserOtp,
        meta: { requiresAuth: true }
      },
      {
        path: 'user_registration',
        name: 'user_registration',
        component: UserRegistration
      },
      {
        path: 'id_verification',
        name: 'id_verification',
        component: IdVerification,
        meta: { requiresAuth: true }
      },
      {
        path: 'facial_biometrics',
        name: 'facial_biometrics',
        component: FacialBiometrics,
        meta: { requiresAuth: true }
      },
      {
        path: 'company_verification',
        name: 'company_verification',
        component: CompanyVerification,
        meta: { requiresAuth: true }
      },
      {
        path: 'company_pending',
        name: 'company_pending',
        component: CompanyPending,
        meta: { requiresAuth: true }
      },
      {
        path: 'director_consent',
        name: 'director_consent',
        component: DirectorConsent
      },
      
    ]
  },
  {
    path: '/system_admin',
    component: AdminComponents,
    children: [
      {
        path:'admin_dashboard',
        name:'admin_dashboard',
        component: AdminDashboard
      },
      {
        path:'client_applications',
        name:'client_applications',
        component: ClientApplications
      },
      {
        path:'users',
        name:'users',
        component: SystemUsers
      },
      {
        path:'view_admin/:user_id',
        name:'view_admin',
        component: ViewAdmin
      },
      {
        path:'view_client/:user_id',
        name:'view_client',
        component: ViewClient

      },
      {
        path:'view_pending_application/:user_id',
        name:'view_pending_application',
        component: ViewPendingApplication
      },
      {
        path:'view_approved_application/:user_id',
        name:'view_approved_application',
        component: ViewApprovedApplication
      },
      {
        path:'view_declined_application/:user_id',
        name:'view_declined_application',
        component: ViewDeclinedApplication
      },
      {
        path:'view_deactivated_application/:user_id',
        name:'view_deactivated_application',
        component: ViewDeactivatedApplication
      },
      {
        path:'view_customer/:user_id',
        name:'view_customer',
        component: ViewCustomer

      },
      {
        path:'view_client_admin/:user_id',
        name:'view_client_admin',
        component: ViewClientAdmin

      },
      {
        path:'service_applications',
        name:'service_applications',
        component: ServiceApplications
      },
      {
        path: 'logs/',
        name: 'view_logs',
        component: ViewLogs
      },
      {
        path: 'view_requested_services/',
        name: 'view_requested_services',
        component: ClientRequestedServices
      },
    ]
  },
  {
    path: '/client',
    component: ClientDashboard,
    children: [
      {
        path:'project_table',
        name:'project_table',
        component: ProjectTable
      },
      {
        path: 'project_dashboard/',
        name: 'project_dashboard',
        component: ProjectDashboard
      },
      {
        path: 'question_management/',
        name: 'question_management',
        component: QuestionManagement
      },
      {
        path: 'form_management/',
        name: 'form_management',
        component: FormManagement
      },
      {
        path: 'manage_form/',
        name: 'manage_form',
        component: ManageForm
      },
      {
        path: 'template_management/',
        name: 'template_management',
        component: TemplateManagement
      },
      {
        path: 'template_configuration/:template_id',
        name: 'template_configuration',
        component: TemplateConfiguration
      },
      {
        path: 'service_dashboard/',
        name: 'service_dashboard',
        component: ServiceDashboard,
        props: true
      },
      {
        path: 'edit_workflow/',
        name: 'edit_workflow',
        component: EditWorkflow,
        props: true
      },
      {
        path: 'view_workflow/',
        name: 'view_workflow',
        component: ViewWorkflow,
        props: true
      },
 
      {
        path: 'workflow_services/',
        name: 'workflow_services',
        component: WorkflowServices
      },
      {
        path: 'client_services/',
        name: 'client_services',
        component: ClientServices,
      },
      {
        path: 'arrange_workflow/',
        name: 'arrange_workflow',
        component: ArrangeWorkflow,
      },
      {
        path: 'client_admin/',
        name: 'client_admin',
        component: ClientAdmin,
      },
      {
        path: 'reasons_for_usage/',
        name: 'reasons_for_usage',
        component: ReasonsForUsage,
      },
      {
        path: 'upload_consent/',
        name: 'upload_consent',
        component: UploadConsent,
      },
      {
        path: 'confirm_workflow/',
        name: 'confirm_workflow',
        component: ConfirmWorkflow,
      },
      {
        path: 'workflow_dashboard/',
        name: 'workflow_dashboard',
        component: WorkflowDashboard,
      },
      {
        path: 'view_customers/',
        name: 'view_customers',
        component: ViewCustomers,
      },
      {
        path: 'view_service/:id',
        name: 'view_service',
        component: ViewService
      },
      {
        path: 'instant_verify/',
        name: 'instant_verify',
        component: InstantVerify
      },
      {
        path: 'instant_services/',
        name: 'instant_services',
        component: InstantServices
      },
      {
        path: 'services/',
        name: 'services',
        component: YourServices
      },
      {
        path: 'logs/',
        name: 'verify_logs',
        component: VerifyLogs
      },
      {
        path: 'customer_data/:id',
        name: 'customer_data',
        component: CustomerData
      },
      {
        path: 'create_asset',
        name: 'create_asset',
        component: CreateAsset
      },
      {
        path: '/view_asset/:id',
        name: 'view_asset',
        component: ViewAsset
      },
    ]
  },
  {
    path: '/',
    component: CustomerIndex,
    children: [
      {
        path: 'customer_consent',
        name: 'customer_consent',
        component: CustomerConsent
      },
      
    ]
    
  },
  {
    path: '/',
    component: CustomerDashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'assigned_workflows',
        name: 'assigned_workflows',
        component: AssignedWorkflows
      },
      {
        path: 'customer_profile',
        name: 'customer_profile',
        component: CustomerProfile
      },
    ]
  },
  {
    path: '/thank_you_page',
    name: 'thank_you_page',
    component: ThankYouPage
  },
  {
    path: '/landing_page',
    name: 'landing_page',
    component: landingPageComponent,
  },
  {
    path: '/verify_landing_page',
    name: 'verify_landing_page',
    component: VerifyLandingPage,
  },
  {
    path: '/service',
    component: ServiceIndex,
    children: [
      {
        path: 'anti_money_laundering',
        name: 'anti_money_laundering',
        component: AntiMoneyLaundering
      },
      {
        path: 'refugee_verification',
        name: 'refugee_verification',
        component: RefugeeVerification
      },
      {
        path: 'company_verification_pdf',
        name: 'company_verification_pdf',
        component: CompanyVerificationPdf
      },
      {
        path: 'qualification_check',
        name: 'qualification_check',
        component: QualificationCheck
      },
      {
        path: 'afis_criminal_check',
        name: 'afis_criminal_check',
        component: AfisCriminalCheck
      },
    ]
  }, 
  {
    path: '/director_info',
    name: 'director_info',
    component: DirectorInfo
  },
  {
    path: '/upload_proof_of_address',
    name: 'upload_proof_of_address',
    component: UploadProofOfAddress
  },
  {
    path: '/eligibility_service_verification',
    name: 'eligibility_service_verification',
    component: EligibilityService
  },
  {
    path: '/online_proof_of_income_verification',
    name: 'online_proof_of_income_verification',
    component: ProofOfIncomeOnline
  },
  {
    path: '/manual_proof_of_income_verification',
    name: 'manual_proof_of_income_verification',
    component: ProofOfIncomeManual
  },
  {
    path: '/search_fcra_verification',
    name: 'search_fcra_verification',
    component: SearchFcra
  },
  {
    path: '/refugee_liveness_verification',
    name: 'refugee_liveness_verification',
    component: RefugeeLivenessVerification
  },
  {
    path: '/rsa_id_liveness_verification',
    name: 'rsa_id_liveness_verification',
    component: RsaIdLivenessVerification
  },
  {
    path: '/question_based_authentication',
    name: 'question_based_authentication',
    component: QuestionBasedAuthentication
  },
  {
    path: '/facial_biometrics_verification',
    name: 'facial_biometrics_verification',
    component: FacialBiometricsVerification
  },
  {
    path: '/cipc_verification',
    name: 'cipc_verification',
    component: CIPCVerification
  },
  {
    path: '/aml_verification',
    name: 'aml_verification',
    component: AMLVerification
  },
  {
    path: '/credit_report',
    name: 'id_credit_report',
    component: CreditReport
  },
  {
    path: '/income_estimator',
    name: 'income_estimation_verification',
    component: IncomeEstimator
  },
  {
    path: '/income_predictor',
    name: 'income_predictor_verification',
    component: IncomePredictor
  },
  {
    path: '/address_match',
    name: 'address_match_verification',
    component: AddressMatch
  },
  {
    path: '/custom_service',
    name: 'custom_service',
    component: CustomService
  },
  {
    path: '/bank_account_verification',
    name: 'bank_account_verification',
    component: BankAccountVerification
  },
  {
    path: '/qual_check',
    name: 'qual_check',
    component: QualCheck
  },
  {
    path: '/afis_crim_check',
    name: 'afis_crim_check',
    component: AfisCrimCheck
  },
  { 
    path: '/workflow/:id/:name', // :id and :name are route parameters
    redirect: to => {
      const { id, name } = to.params;
      return { name: 'registration', query: { workflow_id: id, workflow_name: name } };
    }
  },
  {
    path: '/:customer_application/director_verification',
    redirect: to => {
      return { 
        name: 'landing_page', 
        query: { 
          customer_application: to.params.customer_application,
        }
      };
    }
  },
  {
    path: '/:company_name/:client_admin_id',
    redirect: to => {
      return { 
        name: 'landing_page', 
        query: { 
          client_admin_id: to.params.client_admin_id,
          company_name: to.params.company_name,
        }
      };
    }
  },
  {
    path: '/:workflow_name/:client_admin_id/:workflow_id/:asset_id',
    redirect: to => {
      return { 
        name: 'landing_page', 
        query: { 
          client_admin_id: to.params.client_admin_id,
          workflow_id: to.params.workflow_id,
          asset_id: to.params.asset_id,
          workflow_name: to.params.workflow_name,
        }
      };
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (from.name) {
    localStorage.setItem('previous_route_name', from.name); // Store route name
  }
  next();
});

export default router;
