<div class="container-fluid bg-white vh-100 p-0">
  <ClientLogo />
	<BreadCrumb label="CIPC Verification" />

  <div class="d-flex justify-content-center align-items-center min-vh-50">
    <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
      <div class="form-group mt-3">
        <label for="company_name" class="form-label fw-bold text-xs">Company Name <span class="text-danger fw-bold">*</span></label>
        <div class="col">
          <input 
            id="company_name"
            type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            v-model="company_name" 
            placeholder="Enter Company Name"
          />
        </div>
        <small class="form-text text-danger d-none company-message text-xs fw-bold">
          Please fill out this field
        </small>

        <label for="reg_number" class="form-label fw-bold text-xs mt-3">Company Registration Number <span class="text-danger fw-bold">*</span></label>
        <div class="col">
          <input 
            id="reg_number"
            type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            v-model="reg_number"
            placeholder="Company Registration"
            @input="validate_reg_number"
          />
        </div>
        <small class="form-text text-danger d-none reg-message text-xs fw-bold">
          Please fill out this field
        </small>
        <small class="form-text text-danger d-none text-xs fw-bold error-message"></small>
      </div>
    </div>
  </div>
  <div class="text-center">
    <button 
      type="submit" 
      class="btn bg-green btn-sm mt-2 rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
      @click="save_cipc_info"
    >
      Continue
    </button>   
  </div>
</div>

