export const MAIN_CONTROLLER = {
  data: () => ({
    current_service: null,
  }),

  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },

  methods: {
    async fetch_current_service() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_CURRENT_SERVICE = `${BASE_URL}/verify_api/customer_management_api/workflow_main_contoller/${this.workflow_id}`;

      $.ajax({
        url: GET_CURRENT_SERVICE,
        method: 'GET',
        contentType: 'application/json',
        headers: {
            Authorization: `Token ${TOKEN}`
        },
        success: (response) => {
            Swal.close();
            let service_id = response.service_id;
            this.$store.commit('set_service_id', service_id);
            if (response.status == "Workflow completed") {
              this.$router.push({ name: 'thank_you_page' });
            }
            else if (response.flex_name) {
              this.current_service = response.flex_name;
              this.route_to_current_service();
            }
            else if(response.service_name == "Director info"){
              this.$router.push({ name: 'director_info', params: { workflow_id: this.workflow_id } });
            }
            else {
              this.current_service = 'custom_service';

              this.route_to_current_service();

            }
        },
        error: () => {
            Swal.close();
        }
      });

    },

    route_to_current_service() {
      if (this.current_service) {
        this.$router.push({ name: this.current_service });
      }
    }
  }
};
