import { validateToken } from '@/utils/auth';

export default {
    name: 'VerifyLogs',

    data: () => ({
        loading: false,
        logs: []
    }),

    computed: {
        service_id(){
            return this.$store.getters.get_service_id;
        }
    },
    methods: {
        get_service_logs(){
            const TOKEN = localStorage.getItem('token');
            const GET_SERVICE_LOGS = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_api_logs/${this.service_id}`;
  
            $.ajax({
              url: GET_SERVICE_LOGS,
              method: 'GET',
              contentType: 'application/json',
              headers: {
                Authorization: `Token ${TOKEN}`
              },
              beforeSend: () => {
                Swal.fire({
                  text: 'Loading ...',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  }
                });
              },
              success: (response) => {
                Swal.close();
                this.logs = response.data;

                this.$nextTick(() => {
                    if ($.fn.dataTable.isDataTable('#logs_table')) {
                        $('#logs_table').DataTable().destroy();
                    }
                    $('#logs_table').DataTable({
                      responsive: true
                    });
                });
              },
              error: (error) => {
                Swal.close();
                let error_message = 'Failed to get logs';
                if (error.responseJSON) {
                  const ERROR_RESPONSE = error.responseJSON;
                  if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
                    error_message = ERROR_RESPONSE.error;
                  }
                }
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: error_message,
                  confirmButtonText: 'OK'
                });
              }
            });
          },
    },
    mounted() {
      if (!validateToken(this.$router)) {
        return;
      }
        this.get_service_logs();
    }
};