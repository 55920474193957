<div>
  <div v-for="(field, index) in dynamic_fields" :key="index" class="form-group mb-2">
    <!-- Text Input -->
    <div class="form-group mb-2">
      <input
        v-if="field.has_text"
        type="text"
        class="form-control form-control-sm rounded text-xs w-100"
        v-model="field.value"
        :placeholder="field.question"
        :name="field.question"
      />
    </div>
    <!-- Error Message for Text Input -->
    <small v-if="field.error_message && field.has_text" class="text-danger p-2 text-xxs fw-bold">{{ field.error_message }}</small>

    <!-- Number Input -->
    <div class="form-group mb-2">
      <input
        v-if="field.has_number"
        type="number"
        class="form-control form-control-sm rounded text-xs w-100"
        v-model="field.value"
        :placeholder="field.question"
        :name="field.question"
      />
    </div>
    <small v-if="field.error_message && field.has_number" class="text-danger p-2 text-xxs fw-bold">{{ field.error_message }}</small>

    <!-- Checkbox Options -->
    <div v-if="field.has_checkbox" class="form-group mb-2 me-5 text-start">
      <label class="text-xs mb-2">{{ field.question }}</label>
      <div v-for="option in field.options" :key="option" class="d-flex align-items-center mb-0">
        <input
          type="radio"
          class="form-check-input me-2" 
          v-model="field.value"
          :value="option"
          :id="`checkbox_${index}_${option}`"
        />
        <label :for="`checkbox_${index}_${option}`" class="form-check-label text-xs">{{ option }}</label>
      </div>
    </div>
    <small v-if="field.error_message && field.has_checkbox" class="text-danger p-2 text-xxs fw-bold">{{ field.error_message }}</small>

    <!-- Dropdown for Selections -->
    <div v-if="field.has_selection" class="form-group mb-2 text-start">
      <select
        class="form-select form-select-sm rounded text-xs w-100"
        v-model="field.value"
        :id="`select_${index}`"
      >
        <option value="" disabled hidden>{{ field.question }}</option>
        <option v-for="option in field.options" :key="option" :value="option">{{ option }}</option>
      </select>
    </div>
    <small v-if="field.error_message && field.has_selection" class="text-danger p-2 text-xxs fw-bold">{{ field.error_message }}</small>

    <!-- File Input -->
    <div v-if="field.has_file" class="form-group mb-2 text-start">
      <label class="text-xs">{{ field.question }}</label>
      <input
        type="file"
        accept="*/*" 
        @change="on_file_change($event, index)"
        ref="file_input"
        class="form-control form-control-sm rounded text-xs w-100"
      />
    </div>
    <small v-if="field.error_message && field.has_file" class="text-danger p-2 text-xxs fw-bold">{{ field.error_message }}</small>

    <!-- Date Input -->
    <div v-if="field.has_date" class="form-group mb-2 text-start">
      <label class="text-xs">{{ field.question }}</label>
      <input
        type="date"
        class="form-control form-control-sm rounded text-xs w-100"
        v-model="field.value"
        :placeholder="field.question"
        :name="field.question"
      />
    </div>
    <small v-if="field.error_message && field.has_date" class="text-danger p-2 text-xxs fw-bold">{{ field.error_message }}</small>
  </div>
</div>


 