import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dotenv from 'dotenv';

dotenv.config();

const app = createApp(App);
app.use(router);
app.use(store);

app.mount('#app');

const domain = window.location.hostname;
let client;

if (domain.includes('verify')) {
  client = 'verify';
} else if (domain.includes('wizzit')) {
  client = 'wizzit';
}else if (domain.includes('mobiafrica')) {
  client = 'mobiafrica';
}

// Set client-specific data attribute
if (client) {
    document.documentElement.setAttribute('data-client', client);
}

const logos = {
  verify: require('@/assets/images/new_black_logo2.png'),
  wizzit: require('@/assets/images/Wizzit-Logo-Red.png'),
  mobiafrica: require('@/assets/images/mobi_logo.png'),
};

app.config.globalProperties.$clientLogo = logos[client] || require('@/assets/images/new_black_logo2.png');

app.config.globalProperties.$clientSideBarIcon =
        client === 'verify' || client === 'mobiafrica'
            ? {
                  Dashboard: require('@/assets/images/green_layers.png'),
                  Services: require('@/assets/images/green_services-icon.png'),
                  Assets: require('@/assets/images/green_form_management.png'),
                  Questions: require('@/assets/images/green_questions.png'),
                  Forms: require('@/assets/images/green_form_management.png'),
                  Templates: require('@/assets/images/green_template_management.png'),
                  InstantVerify: require('@/assets/images/green_verify-icon.png'),
                  // Add all other green icons here
              }
            : client === 'wizzit'
            ? {
                  Dashboard: require('@/assets/images/layers.png'),
                  Services: require('@/assets/images/services-icon.png'),
                  Assets: require('@/assets/images/form_management.png'),
                  Questions: require('@/assets/images/questions.png'),
                  Forms: require('@/assets/images/form_management.png'),
                  Templates: require('@/assets/images/template_management.png'),
                  InstantVerify: require('@/assets/images/verify-icon.png'),
                  // Add all other red icons here
              }
            : {};

app.config.globalProperties.$clientServiceIcon =
        client === 'verify' || client === 'mobiafrica'
            ? {
                  Services: require('@/assets/images/green_available-services.png'),
                  AvailableServices: require('@/assets/images/green_available-services.png'),
                  // Add all other green icons here
              }
            : client === 'wizzit'
            ? {
                  Services: require('@/assets/images/available-services.png'),
                  AvailableServices: require('@/assets/images/available-services.png'),
                  // Add all other red icons here
              }
            : {};
    
app.config.globalProperties.$clientImage =
        client === 'verify' || client === 'mobiafrica'
            ? {
                  ThankYou: require('@/assets/images/workflow_done.png'),
                  // Add all other green icons here
              }
            : client === 'wizzit'
            ? {
                  ThankYou: require('@/assets/images/red_workflow.png'),
                  // Add all other red icons here
              }
            : {};
    
