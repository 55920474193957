import { validateToken } from '@/utils/auth';

export default {
    name: 'InstantServices',

    data: () => ({
      personnel_services: [],
      compliance_services: [],
      credit_services: [],
      selected_services: [],
      is_selected : false,
      activeTab: 'personnel',
    }),
    computed: {
      project_id() {
        return this.$store.getters.get_application_id;
      }
    },

    methods: {
      showTab(tab) {
        this.activeTab = tab;
      },
      setup_horizontal_scroll() {
        this.$nextTick(() => {
          const container = this.$refs.services_container;
          if (!container) {
            console.error('services_container ref is not defined');
            return;
          }
          let isDragging = false;
          let startX;
          let scrollLeft;

          container.addEventListener('mousedown', (e) => {
            isDragging = true;
            startX = e.pageX - container.offsetLeft;
            scrollLeft = container.scrollLeft;
          });
          container.addEventListener('mouseleave', () => {
            isDragging = false;
          });
          container.addEventListener('mouseup', () => {
            isDragging = false;
          });
          container.addEventListener('mousemove', (e) => {
            if (!isDragging) return;
            e.preventDefault();
            const x = e.pageX - container.offsetLeft;
            const walk = (x - startX) * 2;
            container.scrollLeft = scrollLeft - walk;
          });
        });
      },
      open_modal(service) {
        $('#service_description_label').text(service.service_name);
        $('#service_description').text(service.service_details[0].service_description);
        $('#service_cost').text(service.service_details[0].service_cost);
        $('#service_input').empty();
        $('#service_output').empty();
  
        try {
          const INPUT_STRING = service.service_details[0].service_input.replace(/'/g, '"');
          const OUTPUT_STRING = service.service_details[0].service_output.replace(/'/g, '"');
          const INPUT_ARRAY = JSON.parse(INPUT_STRING);
          const OUTPUT_ARRAY = JSON.parse(OUTPUT_STRING);
  
          INPUT_ARRAY.forEach(input => {
            const INPUT_ITEM = $('#input_items').clone().removeAttr("id").text(input);
            $('#service_input').append(INPUT_ITEM);
          });
  
          OUTPUT_ARRAY.forEach(output => {
            const OUTPUT_ITEM = $('#output_items').clone().removeAttr("id").text(output);
            $('#service_output').append(OUTPUT_ITEM);
          });
        } catch (error) {
          console.error("Error parsing service input/output", error);
        }
  
        $('#service_description_modal').modal('show');
      },
      get_services() {
        const TOKEN = localStorage.getItem('token');
        const GET_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/service_management_api/get_services_api/`;
  
        $.ajax({
          url: GET_SERVICES_API,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          success: (response) => {
            let services = response['services']
            const SERVICES = Array.isArray(services) ? services : [services];

            SERVICES.forEach(service => {
              if (service.service_details && service.service_details.length > 0) {
                let category_name = service.service_details[0].service_categories.category_name;

                if (category_name === 'personnel_verification') {
                  this.personnel_services.push(service);
                } else if (category_name === 'compliance_verification') {
                  this.compliance_services.push(service);
                } else if (category_name === 'credit_verification') {
                  this.credit_services.push(service);
                }
              }
            });
  
            // Update selected services after fetching
            this.get_selected_services();
          },
          error: (error) => {
            let error_message = 'Failed to get services';
            if (error.responseJSON) {
              const ERROR_RESPONSE = error.responseJSON;
              if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
                error_message = ERROR_RESPONSE.error;
              }
            }
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonText: 'OK'
            });
          }
        });
      },
      save_selected_services(service) {
        const TOKEN = localStorage.getItem('token');
        const SAVE_SELECTED_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/select_project_services_api/`;
  
        const selected_service_ids = [
          ...this.personnel_services.filter(service => service.is_selected).map(service => service.id),
          ...this.compliance_services.filter(service => service.is_selected).map(service => service.id),
          ...this.credit_services.filter(service => service.is_selected).map(service => service.id)
        ];

        // Prepare the form data
        const FORM_DATA = {
          service_ids: selected_service_ids,
          is_selected: service.is_selected,
          project_id: this.project_id
        };

        $.ajax({
          url: SAVE_SELECTED_SERVICES_API,
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(FORM_DATA),
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          success: (response) => {
            this.selected_services = response;

          },
          error: (error) => {
            let error_message = 'Failed to save selected services';
            if (error.responseJSON) {
              const ERROR_RESPONSE = error.responseJSON;
              if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
                error_message = ERROR_RESPONSE.error;
              }
            }
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonText: 'OK'
            });
          }
        });
      },
      get_selected_services() {
        const TOKEN = localStorage.getItem('token');
        const GET_SELECTED_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_requested_services/`;

        $.ajax({
          url: GET_SELECTED_SERVICES_API,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: (response) => {
            Swal.close();
            this.selected_services = Array.isArray(response.all_services) ? response.all_services : [];
            this.update_service_selection();
          },
          error: (error) => {
            Swal.close();
            let error_message = 'Failed to get selected services';
            if (error.responseJSON) {
              const ERROR_RESPONSE = error.responseJSON;
              if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
                error_message = ERROR_RESPONSE.error;
              }
            }
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonText: 'OK'
            });
          }
        });
      },
      update_service_selection() {
        if (!Array.isArray(this.selected_services)) {
          this.selected_services = []; // Default to empty array
        }

        // Assuming selected_services contains service IDs
        const selected_service_ids = this.selected_services.map(service => service.service_id);
        const all_services = [...this.personnel_services, ...this.compliance_services, ...this.credit_services];
  
        all_services.forEach(service => {
          service.is_selected = selected_service_ids.includes(service.id);
        });
      },
      select_services(service) {
        if (!Array.isArray(this.selected_services)) {
          return;
        }

        this.toggle_service_selection(service);
        const TOKEN = localStorage.getItem('token');
        const SELECT_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/instant_verify_request_api/`;
      
        const FORM_DATA = {
          service_id: service.id,
          is_selected: service.is_selected, // Ensure this reflects the checkbox state
        };

        $.ajax({
          url: SELECT_SERVICES_API,
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          data: JSON.stringify(FORM_DATA),
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: (response) => {
            Swal.close();
            localStorage.setItem('user_state', 'services_selected');
            let status = response.status;
  
            if (status === 'success'){
                this.toggle_service_selection(service);
            }
          },
          error: (error) => {
            Swal.close();
            let error_message = 'There was an error selecting this service. Please try again.';
            if (error.responseJSON) {
              const ERROR_RESPONSE = error.responseJSON;
              if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
                error_message = ERROR_RESPONSE.error;
              }
            }
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonText: 'OK'
            });
          }
        });
      },
      toggle_service_selection(service) {
        if (service.is_selected) {
          // Only add to selected_services if it isn't already present
          if (!this.selected_services.some(s => s.id === service.id)) {
            this.selected_services.push(service);
          }
        } else {
          // Remove service from selected_services if it's unselected
          this.selected_services = this.selected_services.filter(s => s.id !== service.id);
        }

      },
      route_to() {
        const isAtLeastOneServiceSelected =
          this.personnel_services.some(service => service.is_selected) ||
          this.compliance_services.some(service => service.is_selected) ||
          this.credit_services.some(service => service.is_selected);
        if (isAtLeastOneServiceSelected) {
          this.$router.push({ name: 'arrange_workflow' });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Please select at least one service before proceeding.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        }
      }
    },

    mounted() {
      if (!validateToken(this.$router)) {
        return;
      }
      this.setup_horizontal_scroll();
      this.get_services();
      this.get_selected_services();
    }
  };