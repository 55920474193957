
<div class="container-fluid">
      <div class="row mt-5">
        <div class="col-md-4">
          <div class="card card-bg shadow rounded-3  border-0 p">
              <div class="card-body">
                  <div class="d-flex justify-content-between px-md-1">
                      <div>
                      <h6 >Active Services</h6>
                      <p class="mb-0 sub_heading text-xsm fw-bold">
                        {{ active_services }}
                      </p>
                      </div>
                      <div class="align-self-center rounded-circle p-2 shadow bg-green">
                        <i class="fa fa-cogs fa-lg me-auto secondary-text-dark"></i>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-bg shadow rounded-3 border-0">
              <div class="card-body">
                  <div class="d-flex justify-content-between px-md-1">
                      <div>
                        <h6>Passed Verifications</h6>
                      <p class="mb-0 sub_heading text-xsm fw-bold">
                        {{ passed_verifications }}
                      </p>
                      </div>
                      <div class="align-self-center rounded-circle p-2 shadow bg-green">
                        <i class="fas fa-users fa-lg  secondary-text-dark"></i>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-bg shadow rounded-3 border-0 mb-2">
              <div class="card-body">
                  <div class="d-flex justify-content-between px-md-1">
                      <div>
                        <h6>Failed Verifications</h6>
                      <p class="mb-0 sub_heading text-xsm fw-bold">
                        {{ failed_verifications }}
                      </p>
                      </div>
                      <div class="align-self-center rounded-circle p-2 shadow bg-green">
                        <i class="fas fa-user-shield fa-lg secondary-text-dark"></i>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
</div>