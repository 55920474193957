<form method="post" @submit.prevent="submit_id">
	<div class="text-center mt-3">
		<P class="text-dark fw-bold mb-3 text-x">Please Provide your ID Number</P>
	</div>
	
	<div class="form-group">
		<input type="text"
		class="form-control rounded text-xs" 
		maxlength="13"
		minlength="13" 
		v-model="id_number"
		placeholder="ID Number" 
		@input="allow_num"
		>
		<small class="text-danger p-2 text-xxs fw-bold d-none id-message">Please fill out this field</small>
		<small class="text-danger p-2 text-xxs fw-bold d-none invalid-message"> ID Number invalid. Please provide a valid ID number</small>
		<small class="text-danger p-2 text-xxs fw-bold d-none error-message"></small>
    </div>

	<div class="text-center mt-5">
		<button type="submit" class="btn bg-green btn-lg shadow-lg rounded text-xs fs-6 mt-2 w-75 fw-bold">Continue</button>
	</div>
</form>

<div v-if="get_consent">
</div>

<div id="terms_and_conditions_modal" class="modal" tabindex="-1">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content bg-white">
			
			<div class="modal-body font-poppins text-x">
				<div class="text-center text-secondary">
					<p class="fs-8 text-dark fw-bold">
						Terms and Conditions
					</p>
					<hr class="line">
				</div>
				
				<div class="px-3 modal-info text-xs text-darkblue fw-light">
	
					<p>Introduction to Verify's Ts & Cs:</p>
					<p>
						Welcome to the Terms and Conditions overview for Verify. These terms govern the relationship between users and verify, 
						ensuring compliance with legal regulations and outlining the responsibilities of all parties involved. As part of the 
						onboarding process, we will Verify the information that you provide, ensuring the accuracy and security of our platform. 
						By using our platform, you agree to abide by these terms, which are designed to protect your rights, ensure fair usage, 
						and maintain the integrity of our services.
					</p>
					<p>Compliance with Laws:</p>
					<p> 
						The Client agrees to comply with all relevant laws and regulations regarding the processing of information and the use of 
						Verify services. This includes cooperation with any investigations required by law.
					</p> 
		
					<p>
						User Details and Security:
					</p>
					<p>
						The Client will provide user details as requested by Verify for the purpose of creating login credentials and onboarding. 
						Verify reserves the right to take necessary actions to preserve network security and reliability. The Client must promptly 
						report any security breaches related to Verify services.
					</p>
					<p>
						Warranties:
					</p>
					
					<p>
						Both Parties warrant their validity and authority to enter into the Agreement. Verify does not provide warranties, 
						and is not liable for certain losses except in cases of gross negligence or wilful misconduct
					</p>
		
					<p>Audit Rights:</p>
		
					<p>
						Verify reserves the right to audit the Client's compliance with the Agreement and may request information, data, or records 
						for this purpose. The Client must cooperate with such audits and provide additional information if required.
					</p>
		
					<p>Limitation of Liability:</p>
		
					<p>
						Verify shall not be liable for certain losses unless due to gross negligence or wilful misconduct. The Client is solely liable 
						for any claims arising from actions taken in reliance on information provided by Verify.
					</p>
		
					<p>Breach:</p>
		
					<p>
						Either Party must remedy any breach of the Agreement within seven days of notice. The Aggrieved Party may cancel the Agreement 
						with twenty days' written notice in case of breach.
					</p>
		
					<p>Applicable Law and Jurisdiction:</p>
		
					<p>
						The platform is governed by the laws of the Republic of South Africa, and the Parties consent to the jurisdiction of the 
						Magistrate’s Court of South Africa for legal proceedings.
					</p>
		
					<p>Disputes:</p>
		
					<p>
						Disputes shall be settled by mediation, and if necessary, by arbitration. Both Parties agree to cooperate in good faith to 
						resolve disputes.
					</p>
		
					<p>Anti-Corruption Warranty:</p>
		
					<p>
						Verify is committed to compliance with anti-corruption laws, and the Client acknowledges their obligations under such laws. 
						Breach of anti-corruption laws may result in penalties and legal action.
					</p>
		
					<p>Protection of Personal Information:</p>
					<p>
						Both Parties undertake to comply with the Protection of Personal Information Act (POPI) and agree on the handling and protection 
						of personal information exchanged under the Agreement.
					</p>
		
					<p>Force Majeure:</p>
					<p>
						Neither Party shall be liable for failure to perform obligations due to circumstances beyond their control.
					</p>
		
		
					<p>General:</p>
					<p>
						In case of conflict between the platform and the law, the law shall prevail. Any modifications to the Agreement must be in writing and 
						signed by both Parties. Indulgences granted by either Party shall not constitute waiver of rights. Successors-in-title shall be bound 
						by the Agreement.
					</p>
		
					<p>Confidentiality:</p>
					<p>
						Both Parties agree to keep confidential all information obtained during the term of the Agreement and for a reasonable period thereafter.
					</p>
		
					<p>Intellectual Property:</p>
					<p>
						Any intellectual property developed during the term of the Agreement shall remain the property of the respective Party, unless otherwise 
						agreed upon in writing.
					</p>
		
					<p>Counterparts:</p>
					<p>
						The Agreement may be executed in counterparts, each of which shall be deemed an original, but all of which together shall constitute one and 
						the same instrument.
					</p>
		
					<p>Legal Fees:</p>
					<p>
						In the event of legal action arising from the Agreement, the prevailing Party shall be entitled to recover reasonable attorney fees 
						and court costs.
					</p>
		
					<p>Conclusion:</p>
					<p>
						In conclusion, these Terms and Conditions form the foundation of our platform's operation, providing clarity, transparency, and legal protection 
						for all users. By adhering to these terms, you contribute to a safe and trusted environment where everyone can benefit from our services. 
						We appreciate your cooperation and look forward to serving you while upholding the highest standards of integrity and professionalism.
					</p>
		
				</div>
			</div>
			<div class="modal-footer">
				<div class="row justify-content-between">
				  <div class="col-6">
					<button type="submit" class="btn btn-danger text-xs fw-bold fs-6 mb-2 mt-3 shadow" @click="decline">Decline</button>
				  </div>
				  <div class="col-6">
					<button type="submit" class="btn bg-success text-white text-xs fw-bold fs-6 mb-2 mt-3 shadow" @click="accept">Accept</button>
				  </div>
				</div>
			  </div>
			  
		</div>
	</div>
</div>