<div class="container-fluid">
      <div class="card rounded-3 p-0 mt-4 mx-auto">
        <div class="card-header bg-white border-0 text-dark">
         Why do you need access to this data? Please select from the list below.
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped-custom">
              <thead class="bg-dark text-white">
                <tr>
                  <th class="text-xs">Reasons</th>
                  <th class="text-xs">Select</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(reason, index) in reasons" class="text-xs" :key="reason.id">
                  <td>
                    <p>{{ index + 1 }}. {{ reason.reason }}</p>
                  </td>
                  <td class="text-end">
                    <label class="switch">
                      <input type="checkbox" v-model="reason.is_selected" @change="toggle_selection(reason)">
                      <span class="slider round"></span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="d-flex justify-content-end me-4 mb-5">
          <button type="button" class="btn bg-green rounded-small shadow text-xxs fw-bold" @click="next">Save</button>
        </div>
  </div>
</div>