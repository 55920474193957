
<div class="row mt-0 mb-0">
  <div class="card rounded-3 mx-auto border-3 custom-border col-lg-10 col-md-10 mt-5">
      <h6 class="card-heading fw-bold text-dark mt-3 text-s">User Information</h6>
    <div class="card-body">
      <form>
        <div class="row mb-3">
          <div class="col-md-6">
            <label class="form-label fw-bold"><small>First name</small></label>
            <input v-model="first_name" type="text" class="form-control text-xs" placeholder="First name" required>
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold"><small>Last name</small></label>
            <input v-model="last_name" type="text" class="form-control text-xs" placeholder="Last name" required>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <label class="form-label fw-bold"><small>Email</small></label>
            <input v-model="email" type="email" class="form-control text-xs" placeholder="Email" required>
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold"><small>Phone number</small></label>
            <input v-model="phone_number" type="tel" class="form-control text-xs" placeholder="Enter phone number" maxlength="10" required>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <label class="form-label fw-bold"><small>Date Joined</small></label>
            <input v-model="date_joined" type="text" class="form-control text-xs" placeholder="Date Joined" disabled>
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold"><small>ID Number</small></label>
            <input v-model="id_number" type="text" class="form-control text-xs" placeholder="ID Number" disabled>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn bg-green btn-sm text-xs fw-bold rounded-pill">Edit</button>
        </div>
      </form>
    </div>
  </div>
</div>

