import ClientSidebar from '@/components/ClientSidebar/ClientSidebar.vue';
import NavBar from '@/components/NavBar/NavBar.vue';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import { validateToken } from '@/utils/auth';

export default {
  name: 'ViewCustomers',

  props: ['workflow_id'],

  components: {
    ClientSidebar,
    NavBar
  },
  data: () => ({ customers: [] }),

  methods: {
    get_customers() {
      const TOKEN = localStorage.getItem('token');
      const GET_CUSTOMERS = `${process.env.API_BASE_URL}/verify_api/system_management_api/client_dashboard/${this.workflow_id}/`;

      $.ajax({
        url: GET_CUSTOMERS,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          // Map and format the response data
          this.customers = response.map(customer => ({
            id: customer.id,
            name: `${customer.first_name} ${customer.last_name}`,
            date_joined: this.format_date(customer.date_joined)
          }));
          this.$nextTick(() => {
            $('#table').DataTable();
          });
        },
        error: () => {
          Swal.close();
        }
      });
    },
    format_date(date) {
      return new Date(date).toLocaleDateString();
    }
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_customers();
  }
};







